import { MTLazy } from '@/components';
import Endpoints from '@/configs/router';
import { AppRouter, TEndpoints } from '@/configs/router/router.types';

const RoutesAuth = {
  [Endpoints.Login]: {
    name: 'Marine Atlas Login',
    path: Endpoints.Login,
    element: MTLazy(() => import('@/pages/auth/login')),
    mode: 'public',
    navigate: Endpoints.ShipmentList,
  },
  [Endpoints.SelectCOCompany]: {
    name: 'Select Cargo Owner Company',
    path: Endpoints.SelectCOCompany,
    element: MTLazy(() => import('@/pages/auth/select-co-company')),
    mode: 'private',
    navigate: Endpoints.Login,
  },
  [Endpoints.ForgotPassword]: {
    name: 'Forgot Password',
    path: Endpoints.ForgotPassword,
    element: MTLazy(() => import('@/pages/auth/forgot-password')),
    mode: 'public',
    navigate: Endpoints.ShipmentList,
  },
  [Endpoints.SetNewPassword]: {
    name: 'Set New Password',
    path: Endpoints.SetNewPassword,
    element: MTLazy(() => import('@/pages/auth/set-new-password')),
    mode: 'public',
    navigate: Endpoints.ShipmentList,
  },
  [Endpoints.OTPVerification]: {
    name: 'OTP Verification',
    path: Endpoints.OTPVerification,
    element: MTLazy(() => import('@/pages/auth/otp-verification')),
    mode: 'public',
    navigate: Endpoints.ShipmentList,
  },
  [Endpoints.FirstLogin]: {
    name: 'Welcome to Marine Atlas!',
    path: Endpoints.FirstLogin,
    element: MTLazy(() => import('@/pages/auth/first-login')),
    navigate: Endpoints.ShipmentList,
  },
  [Endpoints.UploadCompanyLogo]: {
    name: 'Upload Company Logo',
    path: Endpoints.UploadCompanyLogo,
    element: MTLazy(() => import('@/pages/auth/upload-company-logo')),
    mode: 'private',
    navigate: Endpoints.Login,
  },
  [Endpoints.Checkpoint]: {
    name: 'We are verifying your request',
    path: Endpoints.Checkpoint,
    element: MTLazy(() => import('@/pages/auth/checkpoint')),
  },
} as Record<TEndpoints, AppRouter>;

export default RoutesAuth;
