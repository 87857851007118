import jwt from 'jwt-decode';
import Cookies from 'universal-cookie';
import { create } from 'zustand';

import kStorageKey from '@/constants/common/storage.constants';

import { useAuthStoreProps } from './auth.types';

const cookies = new Cookies();

const { Cookies: CookiesStorage } = kStorageKey;

const useAuthStore = create<useAuthStoreProps>((set) => {
  return {
    isAuthenticated: !!(
      cookies.get(CookiesStorage.AccessToken) &&
      cookies.get(CookiesStorage.IDToken) &&
      cookies.get(CookiesStorage.RefreshToken)
    ),
    updateIsAuthenticated: (value) =>
      set((state) => ({ ...state, isAuthenticated: value })),
    userInfo:
      cookies.get(CookiesStorage.UserInfoToken) &&
      jwt(cookies.get(CookiesStorage.UserInfoToken)),
    updateUserInfo: (value) => {
      return set((state) => ({
        ...state,
        userInfo: value,
      }));
    },
  };
});

export default useAuthStore;
