import { useCallback, useEffect, useRef, useState } from 'react';

import { useWindowSize } from 'usehooks-ts';

import { TComponentDragPosition } from '@/domains/common/component/component.types';

import { TUseViewerCore } from './ViewerCore.types';
import { checkPositiveNegative } from './ViewerCore.utils';

export const useViewerCore: TUseViewerCore = ({ wrapRef, elementRef }) => {
  const isClicked = useRef<boolean>(false);
  const [position, setPosition] = useState<TComponentDragPosition>({
    startX: 0,
    startY: 0,
    x: 0,
    y: 0,
    lastX: 0,
    lastY: 0,
  });

  const { height: screenHeight, width: screenWidth } = useWindowSize();

  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      isClicked.current = true;
      wrapRef.current?.classList.add('mt-viewer--moving');
      setPosition((prev) => ({
        ...prev,
        startX: e.clientX - prev.lastX,
        startY: e.clientY - prev.lastY,
      }));
    },
    [wrapRef]
  );

  const handleMouseMove = (e: MouseEvent) => {
    if (!isClicked.current) return;

    setPosition((prev) => ({
      ...prev,
      x: e.clientX - prev.startX,
      y: e.clientY - prev.startY,
    }));
  };

  const handleMouseUp = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      isClicked.current = false;
      wrapRef.current?.classList.remove('mt-viewer--moving');
      const imageClientRect = elementRef.current?.getBoundingClientRect();
      const imageWidth = imageClientRect?.width;
      const imageHeight = imageClientRect?.height;
      const isScreenWidthBigger = screenWidth > imageWidth!;
      const isScreenHeightBigger = screenHeight > imageHeight!;

      const boundX = checkPositiveNegative(
        imageClientRect!.left,
        imageClientRect!.right,
        screenWidth
      );

      const outOfBoundX =
        ((imageWidth! - screenWidth) / 2 + screenWidth / 48) *
        (boundX === 'positive' ? 1 : -1);

      const boundY = checkPositiveNegative(
        imageClientRect!.top,
        imageClientRect!.bottom,
        screenHeight
      );
      const outOfBoundY =
        ((imageHeight! - screenHeight) / 2 + screenHeight / 48) *
        (boundY === 'positive' ? 1 : -1);

      let xAxis = {};
      if (isScreenWidthBigger) xAxis = { x: 0, startX: 0 };
      else if (boundX) xAxis = { x: outOfBoundX, startX: outOfBoundX };

      let yAxis = {};
      if (isScreenHeightBigger) yAxis = { y: 0, startY: 0 };
      else if (boundY) yAxis = { y: outOfBoundY, startY: outOfBoundY };

      setPosition((prev) => {
        let lastX = 0;
        if (!isScreenWidthBigger && boundX) lastX = outOfBoundX;
        else if (!isScreenWidthBigger && !boundX) lastX = prev.x;

        let lastY = 0;
        if (!isScreenHeightBigger && boundY) lastY = outOfBoundY;
        else if (!isScreenHeightBigger && !boundY) lastY = prev.y;
        return {
          ...prev,
          ...xAxis,
          ...yAxis,
          lastX,
          lastY,
        };
      });
    },
    [elementRef, wrapRef, screenHeight, screenWidth]
  );

  useEffect(() => {
    if (!elementRef.current || !wrapRef.current) return;

    const elementRefCurrent = elementRef.current;
    const wrapRefCurrent = wrapRef.current;

    elementRefCurrent.addEventListener('mousedown', handleMouseDown);
    elementRefCurrent.addEventListener('mousemove', handleMouseMove);
    elementRefCurrent.addEventListener('mouseup', handleMouseUp);
    wrapRefCurrent.addEventListener('mousemove', handleMouseMove);
    wrapRefCurrent.addEventListener('mouseleave', handleMouseUp);
    return () => {
      elementRefCurrent.removeEventListener('mousedown', handleMouseDown);
      elementRefCurrent.removeEventListener('mousemove', handleMouseMove);
      elementRefCurrent.removeEventListener('mouseup', handleMouseUp);
      wrapRefCurrent.removeEventListener('mousemove', handleMouseMove);
      wrapRefCurrent.removeEventListener('mouseup', handleMouseUp);
    };
  }, [elementRef, wrapRef, handleMouseDown, handleMouseUp]);

  return [position, setPosition];
};
