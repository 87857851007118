import { Layout, Menu } from 'antd';
import type { SiderProps } from 'antd';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import { ICOChevronDown } from '@/assets/ico';
import { useCommonStore } from '@/store/common';

import './style.scss';
import useMenuList from './MTSider.hooks';

const { Sider } = Layout;

const MTSider: React.FC<SiderProps> = ({ className, ...rest }) => {
  const { pathname } = useLocation();
  const { list: menuList } = useMenuList();

  const { isSidebarCollapsed, updateIsSidebarCollapsed } = useCommonStore(
    (state) => ({
      isSidebarCollapsed: state.isSidebarCollapsed,
      updateIsSidebarCollapsed: state.updateIsSidebarCollapsed,
    })
  );

  if (!menuList.length) return null;

  return (
    <Sider
      className={cx('mt-sider', className)}
      collapsible
      collapsed={isSidebarCollapsed}
      trigger={null}
      width={250}
      id="MTSider"
      {...rest}>
      <div
        className="mt-sider__btn"
        onClick={() => updateIsSidebarCollapsed(!isSidebarCollapsed)}>
        <ICOChevronDown
          className={cx(
            'mt-sider__btn__ico',
            isSidebarCollapsed && 'mt-sider__btn__ico--collapsed'
          )}
        />
      </div>
      <Menu
        className={cx('mt-sider__menu', isSidebarCollapsed && 'collapsed')}
        mode="inline"
        triggerSubMenuAction="click"
        items={menuList}
        selectedKeys={[`/${pathname.split('/')?.[1]}`]} // To keep it seeing only the first endpoints like `/s`, or `/m`.
        getPopupContainer={(element) =>
          document?.getElementById('MTSider') || element
        }
      />
    </Sider>
  );
};

export default MTSider;
