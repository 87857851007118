import React, { memo } from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import { Popover } from 'antd';

import MTChip from '@/components/mt-chip';

import { MTTableTagProps } from './MTTable.Tag.types';

import './style.scss';

const ValueMap = memo<Omit<MTTableTagProps, 'maxShow'>>(({ value, dir }) => (
  <>
    {value?.map((e, i) => (
      <MTChip color="white" key={i}>
        {dir ? (e as Record<string, string>)[dir] : (e as string)}
      </MTChip>
    ))}
  </>
));

const TagWrapper = memo<{ children: React.ReactNode }>(({ children }) => {
  return <div className="mt-table-tag">{children}</div>;
});

const Tag: React.FC<MTTableTagProps> = ({ value = [], dir, maxShow = 3 }) => {
  if (value?.length < 1) return <>-</>;

  return (
    <TagWrapper>
      <ValueMap value={value?.slice(0, maxShow)} dir={dir} />
      {value?.length > maxShow && (
        <Popover
          content={
            <TagWrapper>
              <ValueMap value={value} dir={dir} />
            </TagWrapper>
          }
          placement="left"
          trigger="click">
          <button className="mt-table-tag__button">
            <EllipsisOutlined />
          </button>
        </Popover>
      )}
    </TagWrapper>
  );
};

export default Tag;
