enum EndpointsBase {
  BaseHome = '/',
  BaseSettings = '/settings',
  BaseShipment = '/shipment',
  BaseMonitoring = '/monitoring',
  BaseDocumentCollection = '/document-collection',
  BaseUserManagement = '/profile',

  // Endpoints that use query as key
  // #region query `modal-type=`
  BaseSwitchCompany = 'switch-company',
  BaseUploadCompanyLogo = 'upload-company-logo',
  // #endregion
}

export default EndpointsBase;
