import { forwardRef } from 'react';

import { CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import cx from 'classnames';

import type { ViewerOperationsProps } from './ViewerOperations.types';

import './style.scss';

const ViewerOperations = forwardRef<HTMLDivElement, ViewerOperationsProps>(
  (
    {
      currentPage,
      handleLeftPagination,
      handleRightPagination,
      isLeftHandleDisabled,
      isMulti,
      isRightHandleDisabled,
      onClose,
      totalPages,
      viewerOperations,
    },
    ref
  ) => {
    return (
      <div
        className={cx('mt-viewer-operations__wrapper', 'mt-fade', 'mt-fade-in')}
        ref={ref}>
        {isMulti && (
          <>
            <div
              className={cx(
                'mt-viewer-operations__switch--left',
                isLeftHandleDisabled &&
                  'mt-viewer-operations__switch--left--disabled'
              )}
              onClick={handleLeftPagination}>
              <LeftOutlined />
            </div>
            <div
              className={cx(
                'mt-viewer-operations__switch--right',
                isRightHandleDisabled &&
                  'mt-viewer-operations__switch--right--disabled'
              )}
              onClick={handleRightPagination}>
              <RightOutlined />
            </div>
          </>
        )}
        <ul className="mt-viewer-operations">
          {isMulti && (
            <li className="mt-viewer-operations__progress">
              {currentPage} / {totalPages}
            </li>
          )}

          <li
            className={cx('mt-viewer-operations__operation')}
            onClick={onClose}>
            <CloseOutlined className="mt-viewer-operations__operation__icon" />
          </li>
          {viewerOperations &&
            viewerOperations.map((e) => (
              <li
                className={cx(
                  'mt-viewer-operations__operation',
                  e.disabled && 'mt-viewer-operations__operation--disabled'
                )}
                key={e.name}
                onClick={e.onClick}>
                {e.icon}
              </li>
            ))}
        </ul>
      </div>
    );
  }
);

export default ViewerOperations;
