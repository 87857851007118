enum EndpointsAuth {
  FirstLogin = '/first-login',
  ForgotPassword = '/forgot-password',
  Login = '/login',
  OTPVerification = '/otp-verification',
  SelectCOCompany = '/select-co-company',
  SetNewPassword = '/new-password',
  UploadCompanyLogo = '/upload-company-logo',
  Checkpoint = '/checkpoint',
}

export default EndpointsAuth;
