import { AxiosError, HttpStatusCode } from 'axios';

import { TCommonApiError } from '@/domains/common';

export const handleAxiosApiError = (
  error: AxiosError<TCommonApiError | undefined>
): TCommonApiError => {
  let errorResponse;
  if (error.response) {
    if (error.response.data) {
      errorResponse = {
        status: error.response.data.status,
        error: {
          ...error.response.data.error,
          message: error.response.data.error.message,
        },
      };
    } else {
      errorResponse = {
        status: error.response.status,
        error: {
          message: error.message,
        },
      };
    }
  } else {
    errorResponse = {
      status: error.status ?? HttpStatusCode.RequestTimeout,
      error: {
        message: error.status
          ? error.message
          : 'You are disconnected from the internet!',
      },
    };
  }

  if (
    errorResponse.status === HttpStatusCode.InternalServerError ||
    !errorResponse.error.message
  ) {
    errorResponse = {
      status: errorResponse.status,
      error: { message: 'Sorry, Something Went Wrong' },
    };
  } else if (
    [
      HttpStatusCode.TooManyRequests,
      HttpStatusCode.ServiceUnavailable,
      HttpStatusCode.GatewayTimeout,
    ].includes(errorResponse.status)
  ) {
    errorResponse = {
      status: errorResponse.status,
      error: { message: 'Server is currently busy, Please try again later!' },
    };
  }

  return errorResponse;
};

/**
 * Gets All Keys from Nested Object
 *
 * For example, given an object.
 * ```ts
 * const obj = {
 *      "name": "Sukma",
 *      "phone": "08123123123",
 *      "favorites": [
 *          { "movie": "Ant Man", "series": "Startup" },
 *          { "movie": "The Avengers", "series": "Itaewon Class" },
 *       ]
 * };
 *
 * getAllKeysFromNestedObject(obj);
 * ```
 * result:
 * ```
 * [
 *    ["name"],
 *    ["phone"],
 *    ["favorites", 0, "movie"],
 *    ["favorites", 0, "series"],
 *    ["favorites", 1, "movie"],
 *    ["favorites", 1, "series"]
 * ]
 * ```
 */
export const getAllKeysFromNestedObject = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: Record<string, any>,
  prefix: (string | number)[] = [],
  result: (string | number)[][] = []
): (string | number)[][] => {
  for (const key in obj) {
    const nestedPrefix: (string | number)[] = [...prefix, key];

    if (Array.isArray(obj[key])) {
      if (obj[key].length === 0) result.push(nestedPrefix);
      for (let i = 0; i < obj[key].length; i++) {
        if (typeof obj[key][i] === 'object') {
          getAllKeysFromNestedObject(obj[key][i], [...nestedPrefix, i], result);
        } else {
          result.push(nestedPrefix);
        }
      }
    } else if (typeof obj[key] === 'object') {
      getAllKeysFromNestedObject(obj[key], nestedPrefix, result);
    } else {
      result.push(nestedPrefix);
    }
  }

  return result;
};

/**
 * Gets the indexes of the same items.
 *
 * For example, given an array of string.
 * ```ts
 * const arr = ['Venti', 'Zhongli', 'Ei', 'Zhongli', 'Nahida', 'Venti'];
 * getDuplicateIndexListFromArray(arr); // [0, 1, 3, 5]
 * getDuplicateIndexListFromArray(arr, 'Venti'); // [0, 5]
 * getDuplicateIndexListFromArray(arr, 'Zhongli'); // [1, 3]
 * ```
 */
export const getDuplicateIndexListFromArray = (
  arr: unknown[],
  value?: string
): number[] => {
  const dupIdxMap: Record<string, number[]> = {};

  for (let index = 0; index < arr.length; index++) {
    if (!arr[index]) continue;
    dupIdxMap[String(arr[index])] = dupIdxMap[String(arr[index])] ?? [];
    dupIdxMap[String(arr[index])].push(index);
  }

  if (value) {
    const valIdxList = dupIdxMap[value] ?? [];
    return valIdxList.length > 1 ? valIdxList : [];
  }

  return Object.values(dupIdxMap)
    .filter((idxList) => idxList.length > 1)
    .flat();
};
