import React, { forwardRef } from 'react';

import cx from 'classnames';

import { MTCard } from '@/components';

import {
  MTLayoutCMSCardVerticalWrapperProps,
  MTLayoutCMSCardProps,
  MTLayoutCMSCardHorizontalProps,
  MTLayoutCMSCardVerticalProps,
} from './MTLayoutCMSCard.types';
import './style.scss';

const Horizontal: React.FC<MTLayoutCMSCardHorizontalProps> = ({
  className,
  label,
  style,
  value = '-',
}) => (
  <div className={cx('mt-layout-cms-card-horizontal', className)} style={style}>
    <h5>{label}</h5>
    <p>{value}</p>
  </div>
);

const VerticalWrapper: React.FC<MTLayoutCMSCardVerticalWrapperProps> = ({
  children,
  className,
  column = 3,
  style,
}) => (
  <div
    className={cx('mt-layout-cms-card-vertical-wrapper', className)}
    style={{ gridTemplateColumns: `repeat(${column}, 1fr)`, ...style }}>
    {children}
  </div>
);

const Vertical: React.FC<MTLayoutCMSCardVerticalProps> = ({
  className,
  label,
  children,
  style,
}) => (
  <div className={cx('mt-layout-cms-card-vertical', className)} style={style}>
    <h5>{label}</h5>
    {children}
  </div>
);

const MTLayoutCMSCard = Object.assign(
  forwardRef<HTMLDivElement, MTLayoutCMSCardProps>(
    ({ children, className, title, ...rest }, ref) => {
      return (
        <MTCard
          {...rest}
          padding="sm"
          className={cx('mt-layout-cms-card', className)}
          ref={ref}>
          {title && (
            <>
              <h3 className="mt-layout-cms-card__title">{title}</h3>
              <span className="mt-layout-cms-card__separator" />
            </>
          )}
          {children}
        </MTCard>
      );
    }
  ),
  {
    Horizontal,
    VerticalWrapper,
    Vertical,
  }
);

export default MTLayoutCMSCard;
