import { useMemo, useState } from 'react';

import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useLocation } from 'react-router-dom';

import MTButton from '@/components/mt-button';
import MTCheckboxMulti from '@/components/mt-checkbox/multi';
import { paramParsify } from '@/utils/url';

import type { MTTableFilterProps } from './MTTable.Filter.types';
import './style.scss';

const Filter: React.FC<MTTableFilterProps> = ({
  onClick,
  onChange,
  columns,
  options,
  filterKey,
}) => {
  const { search } = useLocation();

  const parsed = paramParsify(search);

  const [data, setData] = useState<CheckboxValueType[]>(
    (parsed?.[filterKey] as string[]) || []
  );

  const filteredColumns = useMemo(() => {
    if (!options) return 1;
    if (columns) return columns;

    if (options?.length >= 11) {
      return 3;
    } else if (options?.length >= 5) {
      return 2;
    } else return 1;
  }, [columns, options]);

  return (
    <div className="mt-table-filter">
      <MTCheckboxMulti
        className="mt-table-filter__multi_checkbox"
        style={{ gridTemplateColumns: `repeat(${filteredColumns}, 1fr)` }}
        onChange={(values: CheckboxValueType[]) => {
          setData(values);
          onChange(values);
        }}
        options={options}
        value={data}
      />

      <div className="mt-table-filter__divider" />

      <MTButton
        className="mt-table-filter__button"
        onClick={() => onClick(data)}>
        Apply
      </MTButton>
    </div>
  );
};

export default Filter;
