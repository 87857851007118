import { MTCard, MTLazyComponent } from '@/components';
import { MTTableLoader } from '@/components/mt-table';

import type { MTLayoutAuthProps } from './auth';
import { MTLayoutCMSLoader, type MTLayoutCMSProps } from './cms';
import MTLayoutCMSCard from './cms-card';
import { MTLayoutCMSFormProps } from './cms-form';
import MTLayoutCMSMultipleSearchBar from './cms-multiple-searchbar';
import MTLayoutCMSSingleSearchBar from './cms-single-searchbar';
import { MTLayoutCMSTabProps } from './cms-tab';
import { MTLayoutCMSUpdateLogProps } from './cms-update-log';
import { MTLayoutErrorProps } from './error';
import MTLayoutLoader from './loader';

const MTLayoutAuth = MTLazyComponent<MTLayoutAuthProps>(
  () => import('./auth'),
  <MTLayoutLoader />
);

const MTLayoutError = MTLazyComponent<MTLayoutErrorProps>(
  () => import('./error'),
  <MTLayoutLoader />
);

const MTLayoutCMS = MTLazyComponent<MTLayoutCMSProps>(
  () => import('./cms'),
  <MTLayoutCMSLoader />
);

const MTLayoutCMSForm = MTLazyComponent<MTLayoutCMSFormProps>(
  () => import('./cms-form'),
  <MTLayoutCMSLoader />
);

const MTLayoutCMSTab = MTLazyComponent<MTLayoutCMSTabProps>(
  () => import('./cms-tab'),
  <MTLayoutCMSLoader />
);

const MTLayoutCMSUpdateLog = MTLazyComponent<MTLayoutCMSUpdateLogProps>(
  () => import('./cms-update-log'),
  <MTCard style={{ padding: '1rem' }}>
    <MTTableLoader />
  </MTCard>
);

export {
  MTLayoutAuth,
  MTLayoutCMS,
  MTLayoutCMSCard,
  MTLayoutCMSForm,
  MTLayoutCMSMultipleSearchBar,
  MTLayoutCMSSingleSearchBar,
  MTLayoutCMSTab,
  MTLayoutCMSUpdateLog,
  MTLayoutError,
  MTLayoutLoader,
};
