import { MoreOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { Link } from 'react-router-dom';

import { MTTableActionProps } from '.';

import './style.scss';

const Action: React.FC<MTTableActionProps> = ({ items, canEdit, path }) => {
  const menuItems: ItemType[] = [
    ...(canEdit
      ? [
          {
            key: 'edit',
            label: (
              <Link
                style={{ fontWeight: 400 }}
                to={path.edit}
                state={{ isEdit: true }}>
                Edit
              </Link>
            ),
          },
        ]
      : []),
    ...(path
      ? [
          {
            key: 'detail',
            label: (
              <Link style={{ fontWeight: 400 }} to={path.detail}>
                Detail
              </Link>
            ),
          },
        ]
      : []),
  ];

  return (
    <Dropdown
      menu={{
        items:
          (typeof items === 'function' ? items(menuItems) : items) || menuItems,
      }}
      placement="bottomRight"
      trigger={['click']}>
      <button className="mt-table-action">
        <MoreOutlined />
      </button>
    </Dropdown>
  );
};

export default Action;
