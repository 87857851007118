import React from 'react';

import ReactDOM from 'react-dom/client';

import App from './App';
import { initMixpanel } from './mixpanel';
import { initSentry } from './sentry';

import '@/styles/global.scss';

initSentry();
initMixpanel();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
