enum EApiUser {
  chooseCargoOwnerCompany = '/auth/choose-cargo-owner',
  getCargoOwnersCompanies = '/externals/cargo-owners',
  login = '/auth/login',
  reactivateAccount = '/users/:id/reactivate',
  resendInvitation = '/users/:id/resend',
  removeAccount = '/users/:id',
  requestForgotPasswordCode = '/forgot-password/request-code',
  submitForgotPassword = '/forgot-password/submit',
  userDetail = '/users/:id',
  userUpdateLog = '/users/:id/update-logs',
  users = '/users',
  verifyForgotPassword = '/forgot-password/verify',
  checkURLForgotPassword = '/forgot-password/validate-url',
  userIsExist = '/users/is-exist',
}

export default EApiUser;
