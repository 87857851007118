import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import cx from 'classnames';

import '@/scss/utils/_padding.scss';

import type { MTSpinnerProps } from './MTSpinner.types';
import './style.scss';

const MTSpinner: React.FC<MTSpinnerProps> = ({
  block = true,
  className,
  size = '1.25rem',
  ...rest
}) => {
  return (
    <div
      className={cx(
        'mt-spinner',
        'p--1',
        block && 'mt-spinner--block',
        className
      )}
      {...rest}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: size }} spin />} />
    </div>
  );
};

export default MTSpinner;
