import EndpointsBase from '../base';

enum EndpointsShipment {
  ShipmentList = EndpointsBase.BaseShipment,
  ShipmentDetail = `${EndpointsBase.BaseShipment}/:id`,
  ShipmentUpdateLog = `${EndpointsBase.BaseShipment}/:id/log`,
  ShipmentInformation = `${EndpointsBase.BaseShipment}/:id/information`,
}

export default EndpointsShipment;
