import { Layout, Skeleton, Space } from 'antd';

import { MTCard, MTHeader, MTSider } from '@/components';
import './style.scss';
import { MTTableLoader } from '@/components/mt-table';

const MTLayoutCMSMultipleSearchbarLoader: React.FC = () => {
  return (
    <Layout className="mt-layout-cms">
      <MTHeader />
      <Layout hasSider>
        <MTSider />
        <Layout className="mt-layout-cms__inner">
          <div className="mt-layout-cms__main">
            <Space
              direction="vertical"
              size="large"
              className="mt-layout-cms__header">
              <Skeleton.Input />
              <Space direction="vertical" style={{ width: '100%' }}>
                <Skeleton paragraph={false} style={{ width: '12rem' }} />
                <Space size="large" style={{ marginTop: '2rem' }}>
                  <Space direction="vertical">
                    <Skeleton paragraph={false} style={{ width: '8rem' }} />
                    <Skeleton.Input />
                  </Space>
                  <Space direction="vertical">
                    <Skeleton paragraph={false} style={{ width: '8rem' }} />
                    <Skeleton.Input />
                  </Space>
                  <Space direction="vertical">
                    <Skeleton paragraph={false} style={{ width: '8rem' }} />
                    <Skeleton.Input />
                  </Space>
                  <Space direction="vertical">
                    <Skeleton paragraph={false} style={{ width: '8rem' }} />
                    <Skeleton.Input />
                  </Space>
                </Space>
              </Space>
            </Space>
            <Layout.Content className="mt-layout-cms__content">
              <MTCard style={{ padding: '1rem', marginTop: '1rem' }}>
                <MTTableLoader />
              </MTCard>
            </Layout.Content>
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MTLayoutCMSMultipleSearchbarLoader;
