import { AppRouter, TEndpoints } from '@/configs/router/router.types';

import RoutesAuth from './auth';
import RoutesBase from './base';
import RoutesDocumentCollection from './document-collection';
import RoutesErrorHandling from './error-handling';
import RoutesExample from './example';
import RoutesMonitoring from './monitoring';
import RoutesShipment from './shipment';
import RoutesUserManagement from './user-management';

export const RouterMap = {
  ...RoutesAuth,
  ...RoutesExample,
  ...RoutesBase,
  ...RoutesShipment,
  ...RoutesMonitoring,
  ...RoutesDocumentCollection,
  ...RoutesUserManagement,
  ...RoutesErrorHandling,
} as Record<TEndpoints, AppRouter>;

const RouterList = Object.values(RouterMap);

export default RouterList;
