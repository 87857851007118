import { MTLazy } from '@/components';
import Endpoints from '@/configs/router';

const RoutesErrorHandling = {
  [Endpoints.Unauthorized]: {
    name: 'Error Unauthorized',
    path: Endpoints.Unauthorized,
    element: MTLazy(() => import('@/pages/error/unauthorized')),
  },
  [Endpoints.Wildcard]: {
    name: 'Error 404',
    path: Endpoints.Wildcard,
    element: MTLazy(() => import('@/pages/error/404')),
  },
};

export default RoutesErrorHandling;
