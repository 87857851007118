import { useRef } from 'react';

import { createPortal } from 'react-dom';
import { Document, Page, pdfjs } from 'react-pdf';

import { ViewerMask, ViewerOperations } from '../core';

import { useMTViewerPDF } from './MTViewerPDF.hooks';
import type { MTViewerPDFProps } from './MTViewerPDF.types';

import 'react-pdf/dist/esm/Page/TextLayer.css';

import '@/scss/utils/_animations.scss';

import './style.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const MTViewerPDF: React.FC<MTViewerPDFProps> = ({
  isOpen,
  isShowOperators = true,
  onClose: onClose,
  src,
}) => {
  const PDFRef = useRef<HTMLDivElement>(null);
  const PDFPreviewRef = useRef<HTMLDivElement>(null);
  const PDFWrapRef = useRef<HTMLDivElement>(null);
  const ViewerMaskRef = useRef<HTMLDivElement>(null);
  const ViewerOperationsRef = useRef<HTMLDivElement>(null);

  const {
    currentPage,
    handleClose,
    handlePagination,
    handleScrollWheelPDF,
    isShowDOM,
    onDocumentLoadSuccess,
    pdfPosition,
    rotate,
    scale,
    totalPages,
    viewerOperations,
  } = useMTViewerPDF({
    PDFPreviewRef,
    PDFRef,
    PDFWrapRef,
    ViewerMaskRef,
    ViewerOperationsRef,
    isOpen,
    onClose,
    src,
  });

  return (
    <>
      {createPortal(
        <ViewerMask
          isShow={isShowDOM}
          onClick={handleClose}
          ref={ViewerMaskRef}>
          <div
            className="mt-viewer-pdf__wrapper"
            tabIndex={-1}
            ref={PDFWrapRef}
            style={{ display: 'none' }}
            onClick={(e) => {
              if (
                PDFRef.current &&
                !PDFRef.current.contains(e.target as Element)
              ) {
                handleClose();
              }
            }}>
            <div
              role="dialog"
              aria-modal="true"
              className="mt-viewer-pdf__preview"
              ref={PDFPreviewRef}>
              <div className="mt-viewer-pdf__preview__content">
                <div className="mt-viewer-pdf__preview__body">
                  <div className="mt-viewer-pdf__preview__pdf__wrapper">
                    <div
                      style={{
                        translate: `${pdfPosition.x}px ${pdfPosition.y}px 0px`,
                      }}
                      className="mt-viewer-pdf__preview__pdf__handler"
                      onWheel={handleScrollWheelPDF}>
                      <Document
                        inputRef={PDFRef}
                        className="mt-viewer-pdf__preview__pdf"
                        file={src}
                        onLoadSuccess={({ numPages }) =>
                          onDocumentLoadSuccess(numPages)
                        }>
                        <Page
                          pageNumber={currentPage + 1}
                          renderAnnotationLayer={false}
                          scale={scale}
                          height={512}
                          rotate={rotate}
                        />
                      </Document>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ViewerMask>,
        document.body
      )}
      {isShowDOM &&
        createPortal(
          <ViewerOperations
            onClose={handleClose}
            currentPage={currentPage + 1}
            handleLeftPagination={() => handlePagination('prev')}
            handleRightPagination={() => handlePagination('next')}
            isLeftHandleDisabled={currentPage === 0}
            isMulti={true}
            isRightHandleDisabled={currentPage >= totalPages - 1}
            ref={ViewerOperationsRef}
            totalPages={totalPages}
            viewerOperations={isShowOperators ? viewerOperations : []}
          />,
          document.body
        )}
    </>
  );
};

export default MTViewerPDF;
