import EndpointsBase from '../base';

const BaseUserManagementRoleGroup = `${EndpointsBase.BaseUserManagement}/rg`;

enum EndpointsUserManagementRoleGroup {
  UserManagementRoleGroupList = BaseUserManagementRoleGroup,
  UserManagementRoleGroupAdd = `${BaseUserManagementRoleGroup}/add`,
  UserManagementRoleGroupEdit = `${BaseUserManagementRoleGroup}/:id/edit`,

  UserManagementRoleGroupDetail = `${BaseUserManagementRoleGroup}/:id`,
  UserManagementRoleGroupInformation = `${BaseUserManagementRoleGroup}/:id/information`,
  UserManagementRoleGroupUpdateLog = `${BaseUserManagementRoleGroup}/:id/log`,
}

export default EndpointsUserManagementRoleGroup;
