import ApiEndpoints from '@/domains/api-endpoints';
import { TCommonApiResponse } from '@/domains/common/common.types';
import {
  TCheckRoleGroupNameExistParams,
  TCreateRoleGroupParams,
  TGetRoleGroupAccessListResponse,
  TGetRoleGroupDetailParams,
  TGetRoleGroupDetailResponse,
  TGetRoleGroupListParams,
  TGetRoleGroupListResponse,
  TGetRoleGroupUpdateLogParams,
  TGetRoleGroupUpdateLogResponse,
  TUpdateRoleGroupParams,
} from '@/domains/user-management/role-group';
import { EUserPlatformType } from '@/domains/user-management/user/user.constants';
import marineApi from '@/services/services.config';

export default class UserManagementRoleGroupApi {
  getRoleGroupAccessList(): Promise<
    TCommonApiResponse<TGetRoleGroupAccessListResponse>
  > {
    return marineApi.atlas.get(ApiEndpoints.accessList, {
      params: {
        platform_type: EUserPlatformType.CargoOwnerDashboard,
      },
    });
  }

  getRoleGroupList(
    params?: TGetRoleGroupListParams
  ): Promise<TCommonApiResponse<TGetRoleGroupListResponse, 'Pagination'>> {
    return marineApi.atlas.get(ApiEndpoints.roleGroup, { params });
  }

  createRoleGroup(params: TCreateRoleGroupParams): Promise<TCommonApiResponse> {
    return marineApi.atlas.post(ApiEndpoints.roleGroup, params);
  }

  updateRoleGroup({
    id,
    ...params
  }: TUpdateRoleGroupParams): Promise<TCommonApiResponse> {
    return marineApi.atlas.put(
      ApiEndpoints.roleGroupDetail.replace(':id', id),
      params
    );
  }

  checkRoleGroupNameExist(
    params: TCheckRoleGroupNameExistParams
  ): Promise<TCommonApiResponse<boolean>> {
    return marineApi.atlas.post(ApiEndpoints.roleGroupNameExist, params);
  }

  getRoleGroupDetail({
    id,
    ...params
  }: TGetRoleGroupDetailParams): Promise<
    TCommonApiResponse<TGetRoleGroupDetailResponse>
  > {
    return marineApi.atlas.get(
      ApiEndpoints.roleGroupDetail.replace(':id', id),
      { params }
    );
  }

  getRoleGroupUpdateLog({
    id,
    ...params
  }: TGetRoleGroupUpdateLogParams): Promise<
    TCommonApiResponse<TGetRoleGroupUpdateLogResponse, 'Pagination'>
  > {
    return marineApi.atlas.get(
      ApiEndpoints.roleGroupUpdateLog.replace(':id', id),
      { params }
    );
  }
}
