import { forwardRef, useCallback } from 'react';

import type { InputProps, InputRef } from 'antd';
import { Input } from 'antd';
import cx from 'classnames';

import { kRegex } from '@/constants/common/regex.constants';
import './style.scss';

const MTInput = forwardRef<InputRef, InputProps>(
  ({ className, type, ...rest }, ref) => {
    const handleKeyPressNumberOnly = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (kRegex.AtLeastOneNonNumber.test(e.key)) return e.preventDefault();
      },
      []
    );
    return (
      <Input
        className={cx('mt-input', className)}
        ref={ref}
        onKeyPress={type === 'number' ? handleKeyPressNumberOnly : undefined}
        {...rest}
      />
    );
  }
);

export default MTInput;
