import { useMemo } from 'react';

import { MenuProps } from 'antd';
import { Link } from 'react-router-dom';

import { ICODocument, ICOMapPin, ICOShip } from '@/assets/ico';
import Endpoints from '@/configs/router';
import { RouterKey } from '@/domains/common';
import { useRole } from '@/hooks';
import RouterList from '@/routes/routes';

const useMenuList = () => {
  const { isUserAllowed, userAccess } = useRole();

  const list: MenuProps['items'] = useMemo(
    () => [
      ...(userAccess[RouterKey.ALL] || userAccess[RouterKey.SHIPMENT_VIEW]
        ? [
            {
              label: <Link to={Endpoints.ShipmentList}>Shipment</Link>,
              key: Endpoints.ShipmentList,
              icon: (
                <Link to={Endpoints.ShipmentList}>
                  <span className="wrap-icon">
                    <ICOShip />
                  </span>
                </Link>
              ),
            },
          ]
        : []),
      {
        label: <Link to={Endpoints.BaseMonitoring}>Monitoring</Link>,
        key: Endpoints.BaseMonitoring,
        icon: (
          <Link to={Endpoints.BaseMonitoring}>
            <span className="wrap-icon">
              <ICOMapPin />
            </span>
          </Link>
        ),
      },
      {
        label: (
          <Link to={Endpoints.DocumentCollectionList}>Document Collection</Link>
        ),
        key: Endpoints.DocumentCollectionList,
        icon: (
          <Link to={Endpoints.DocumentCollectionList}>
            <span className="wrap-icon">
              <ICODocument />
            </span>
          </Link>
        ),
      },
    ],
    [userAccess]
  );

  const accessibleMenuList = useMemo(
    () =>
      list.filter((menu) => {
        const matchedRoute = RouterList.find(
          (route) => menu?.key === route.path
        );
        return isUserAllowed({
          routerKey: matchedRoute?.routerKey as RouterKey,
        });
      }),
    [isUserAllowed, list]
  );

  return { list: accessibleMenuList };
};

export default useMenuList;
