import { useMemo } from 'react';

import dayjs from 'dayjs';
import { useLocation, matchRoutes } from 'react-router-dom';

import Endpoints from '@/configs/router';
import {
  kMixpanelEventNames,
  kMixpanelPropertyNames,
} from '@/constants/common/mixpanel.constants';
import { mixpanel } from '@/mixpanel';
import { useAuthStore } from '@/store';

import {
  IMixpanelTrackDocs,
  TMixpanelTrackFilterTable,
  IMixpanelTrackSortTable,
  DynamicPayload,
} from './UseMixpanel.types';

const useMixpanel = () => {
  const { userInfo } = useAuthStore();
  const { pathname } = useLocation();

  // #region of event names

  const mixpanelEventDocument = useMemo(() => {
    const routes: Record<string, string>[] = [
      {
        path: Endpoints.ShipmentInformation,
        eventDownload:
          kMixpanelEventNames.SHIPMENT_DETAIL_INFORMATION_DOCUMENT_DOWNLOAD,
        eventViewed:
          kMixpanelEventNames.SHIPMENT_DETAIL_INFORMATION_DOCUMENT_VIEWED,
      },
      {
        path: Endpoints.DocumentCollectionDetail,
        eventDownload:
          kMixpanelEventNames.DOCUMENT_COLLECTIONS_DETAIL_DOCUMENT_DOWNLOAD,
        eventViewed:
          kMixpanelEventNames.DOCUMENT_COLLECTIONS_DETAIL_DOCUMENT_VIEWED,
      },
    ];

    const router = matchRoutes(routes, pathname);

    const matchedEvent = router?.[0]?.route || {};

    return matchedEvent;
  }, [pathname]);

  const mixpanelEventSearch = useMemo(() => {
    const routes: Record<string, string>[] = [
      {
        path: Endpoints.ShipmentList,
        event: kMixpanelEventNames.SHIPMENT_TABLE_SEARCHED,
      },
      {
        path: Endpoints.ShipmentMonitoring,
        event: kMixpanelEventNames.MONITORING_VESSEL_TRACKER_SEARCHED,
      },
      {
        path: Endpoints.MonitoringList,
        event: kMixpanelEventNames.MONITORING_SHIPMENT_TRACKER_SEARCHED,
      },
      {
        path: Endpoints.DocumentCollectionList,
        event: kMixpanelEventNames.DOCUMENT_COLLECTIONS_SEARCHED,
      },
      {
        path: Endpoints.UserManagementUserList,
        event: kMixpanelEventNames.USER_LIST_SEARCHED,
      },
      {
        path: Endpoints.UserManagementRoleGroupList,
        event: kMixpanelEventNames.USER_ROLE_GROUP_SEARCHED,
      },
    ];

    const router = matchRoutes(routes, pathname);

    const matchedEvent = router?.[0]?.route?.event || '';

    return matchedEvent;
  }, [pathname]);

  const mixpanelEventFormValidate = useMemo(() => {
    const routes: Record<string, string>[] = [
      {
        path: Endpoints.UserManagementUserAdd,
        event: kMixpanelEventNames.CREATE_USER_FAILED,
      },
      {
        path: Endpoints.UserManagementUserEdit,
        event: kMixpanelEventNames.EDIT_USER_FAILED,
      },
      {
        path: Endpoints.UserManagementRoleGroupAdd,
        event: kMixpanelEventNames.CREATE_ROLE_GROUP_FAILED,
      },
      {
        path: Endpoints.UserManagementRoleGroupEdit,
        event: kMixpanelEventNames.EDIT_ROLE_GROUP_FAILED,
      },
    ];

    const router = matchRoutes(routes, pathname);

    const matchedEvent = router?.[0]?.route?.event || '';

    return matchedEvent;
  }, [pathname]);

  const mixpanelEventFilterSort = useMemo(() => {
    const routes: Record<string, string>[] = [
      {
        path: Endpoints.ShipmentList,
        eventFilter: kMixpanelEventNames.SHIPMENT_TABLE_FILTERED,
        eventSort: kMixpanelEventNames.SHIPMENT_TABLE_SORTED,
      },
      {
        path: Endpoints.MonitoringList,
        eventFilter: kMixpanelEventNames.MONITORING_SHIPMENT_TRACKER_FILTERED,
        eventSort: kMixpanelEventNames.MONITORING_SHIPMENT_TRACKER_SORTED,
      },
      {
        path: Endpoints.UserManagementUserList,
        eventFilter: kMixpanelEventNames.USER_LIST_FILTERED,
        eventSort: '',
      },
      {
        path: Endpoints.DocumentCollectionList,
        eventFilter: kMixpanelEventNames.DOCUMENT_COLLECTIONS_FILTERED,
        eventSort: kMixpanelEventNames.DOCUMENT_COLLECTIONS_SORTED,
      },
      {
        path: Endpoints.UserManagementRoleGroupList,
        eventFilter: kMixpanelEventNames.USER_ROLE_GROUP_FILTERED,
        eventSort: kMixpanelEventNames.USER_ROLE_GROUP_SORTED,
      },
      {
        path: Endpoints.UserManagementRoleGroupInformation,
        eventFilter: kMixpanelEventNames.USER_ROLE_GROUP_DETAIL_FILTERED,
        eventSort: '',
      },
    ];

    const router = matchRoutes(routes, pathname);

    const matchedEvent = router?.[0]?.route || {};

    return matchedEvent;
  }, [pathname]);

  const mixpanelEventDetailLog: string = useMemo(() => {
    const routes: Record<string, string>[] = [
      {
        path: Endpoints.ShipmentUpdateLog,
        event: kMixpanelEventNames.SHIPMENT_DETAIL_UPDATE_LOG_CHANGE_VIEWED,
      },
      {
        path: Endpoints.UserManagementUserUpdateLog,
        event: kMixpanelEventNames.USER_DETAIL_UPDATE_LOG_CHANGE_VIEWED,
      },
      {
        path: Endpoints.UserManagementRoleGroupUpdateLog,
        event:
          kMixpanelEventNames.USER_ROLE_GROUP_DETAIL_UPDATE_LOG_CHANGE_VIEWED,
      },
    ];

    const router = matchRoutes(routes, pathname);

    const matchedEvent = router?.[0]?.route?.event || '';

    return matchedEvent;
  }, [pathname]);

  // #endregion

  // #region of handle tracking

  const mixpanelTrackDownloadDocs = (payload: IMixpanelTrackDocs) => {
    if (mixpanelEventDocument?.eventDownload) {
      mixpanel.track(mixpanelEventDocument?.eventDownload, {
        [kMixpanelPropertyNames.FILE_NAME]: payload?.src,
        [kMixpanelPropertyNames.EMAIL_ADDRESS]: userInfo?.email,
        [kMixpanelPropertyNames.DOWNLOAD_TIME]: dayjs(new Date()).format(
          'd, hh:mm A'
        ),
      });
    }
  };

  const mixpanelTrackViewedDocs = (payload: IMixpanelTrackDocs) => {
    if (mixpanelEventDocument?.eventViewed) {
      mixpanel.track(mixpanelEventDocument?.eventViewed, {
        [kMixpanelPropertyNames.FILE_NAME]: payload?.src,
        [kMixpanelPropertyNames.EMAIL_ADDRESS]: userInfo?.email,
      });
    }
  };

  const mixpanelTrackFilterTable = (payload: TMixpanelTrackFilterTable) => {
    if (mixpanelEventFilterSort?.eventFilter) {
      mixpanel.track(mixpanelEventFilterSort?.eventFilter, payload);
    }
  };

  const mixpanelTrackSortTable = (payload: IMixpanelTrackSortTable) => {
    if (mixpanelEventFilterSort?.eventSort) {
      mixpanel.track(mixpanelEventFilterSort?.eventSort, payload);
    }
  };

  const mixpanelTrackSearchTable = (payload: DynamicPayload) => {
    if (mixpanelEventSearch) {
      mixpanel.track(mixpanelEventSearch, payload);
    }
  };

  const mixpanelTrackFormValidate = (payload: DynamicPayload) => {
    if (mixpanelEventFormValidate) {
      mixpanel.track(mixpanelEventFormValidate, {
        ...payload,
        [kMixpanelPropertyNames.CARGO_OWNER_NAME]: userInfo?.cargo_owner_name,
      });
    }
  };

  const mixpanelTrackUpdateLogChangeDetail = () => {
    if (mixpanelEventDetailLog) {
      mixpanel.track(mixpanelEventDetailLog, {
        [kMixpanelPropertyNames.EMAIL_ADDRESS]: userInfo?.email,
      });
    }
  };

  // #endregion

  return {
    mixpanelEventDocument,
    mixpanelEventSearch,
    mixpanelEventFilterSort,
    mixpanelEventDetailLog,
    mixpanelTrackViewedDocs,
    mixpanelTrackDownloadDocs,
    mixpanelTrackFilterTable,
    mixpanelTrackSortTable,
    mixpanelTrackSearchTable,
    mixpanelTrackUpdateLogChangeDetail,
    mixpanelTrackFormValidate,
  };
};

export default useMixpanel;
