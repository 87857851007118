import React from 'react';

import { Skeleton } from 'antd';

import { MTCard } from '@/components';
import { MTLayoutCMS } from '@/layouts';

import './style.scss';

const MTLayoutCMSFormLoader: React.FC = () => {
  return (
    <MTLayoutCMS>
      <MTCard className="mt-layout-cms-form__card">
        <div style={{ marginInline: 'auto' }}>
          <Skeleton.Input />
        </div>
        <Skeleton style={{ marginTop: '2rem' }} />
      </MTCard>
    </MTLayoutCMS>
  );
};

export default MTLayoutCMSFormLoader;
