import ApiEndpoints from '@/domains/api-endpoints';
import { TCommonApiResponse } from '@/domains/common/common.types';
import { TUploaderParams, TUploaderResponse } from '@/domains/uploader';

import marineApi from '../services.config';

export default class UploaderAPI {
  uploadRequest(
    params: TUploaderParams
  ): Promise<TCommonApiResponse<TUploaderResponse>> {
    const { config, data } = params;
    return marineApi.barging.post(ApiEndpoints.uploadRequest, data, {
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
      },
      ...config,
    });
  }
}
