import ApiEndpoints from '@/domains/api-endpoints';
import { TCommonApiResponse } from '@/domains/common';
import {
  TGetShipmentDetailParams,
  TGetShipmentDetailResponse,
} from '@/domains/shipment/getShipmentDetail';
import {
  TGetShipmentListParams,
  TGetShipmentListResponse,
} from '@/domains/shipment/getShipmentList';
import {
  TGetShipmentUpdateLogParams,
  TGetShipmentUpdateLogResponse,
} from '@/domains/shipment/getShipmentUpdateLog';

import marineApi from '../services.config';

export default class ShipmentApi {
  getShipmentList(
    params?: TGetShipmentListParams
  ): Promise<TCommonApiResponse<TGetShipmentListResponse, 'Pagination'>> {
    return marineApi.barging.get(ApiEndpoints.shipment, { params });
  }

  getShipmentDetail({
    id,
    ...params
  }: TGetShipmentDetailParams): Promise<
    TCommonApiResponse<TGetShipmentDetailResponse>
  > {
    return marineApi.barging.get(
      ApiEndpoints.shipmentDetail.replace(':id', `${id}`),
      {
        params,
      }
    );
  }

  getShipmentUpdateLog({
    id,
    ...params
  }: TGetShipmentUpdateLogParams): Promise<
    TCommonApiResponse<TGetShipmentUpdateLogResponse, 'Pagination'>
  > {
    return marineApi.barging.get(
      ApiEndpoints.shipmentUpdateLog.replace(':id', id),
      { params }
    );
  }
}
