import { MTLazy } from '@/components';
import Endpoints from '@/configs/router';
import { AppRouter, TEndpoints } from '@/configs/router/router.types';
import { RouterKey } from '@/domains/common';
import { MTLayoutCMSLoader } from '@/layouts/cms';
import { MTLayoutCMSMultipleSearchbarLoader } from '@/layouts/cms-multiple-searchbar';

const RoutesShipment = {
  [Endpoints.ShipmentList]: {
    name: 'Shipment',
    path: Endpoints.ShipmentList,
    element: MTLazy(
      () => import('@/pages/shipment'),
      <MTLayoutCMSMultipleSearchbarLoader />
    ),
    mode: 'private',
    routerKey: RouterKey.SHIPMENT_VIEW,
    asFirst: true,
  },
  [Endpoints.ShipmentDetail]: {
    name: 'Shipment Detail',
    path: Endpoints.ShipmentDetail,
    element: MTLazy(
      () => import('@/pages/shipment/detail'),
      <MTLayoutCMSLoader />
    ),
    mode: 'private',
    routerKey: RouterKey.SHIPMENT_VIEW,
  },
  [Endpoints.ShipmentUpdateLog]: {
    name: 'Shipment Update Log',
    path: Endpoints.ShipmentUpdateLog,
    element: MTLazy(
      () => import('@/pages/shipment/detail/update-log'),
      <MTLayoutCMSLoader />
    ),
    mode: 'private',
    routerKey: RouterKey.SHIPMENT_VIEW,
  },
  [Endpoints.ShipmentInformation]: {
    name: 'Shipment Information',
    path: Endpoints.ShipmentInformation,
    element: MTLazy(
      () => import('@/pages/shipment/detail/information'),
      <MTLayoutCMSLoader />
    ),
    mode: 'private',
    routerKey: RouterKey.SHIPMENT_VIEW,
  },
} as Record<TEndpoints, AppRouter>;

export default RoutesShipment;
