import axios, { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';
import jwtEncode from 'jwt-encode';
import Cookies from 'universal-cookie';

import { MTToast } from '@/components';
import { configCookiesOptions } from '@/configs/cookies/cookies.config';
import Env from '@/configs/environment.config';
import kStorageKey from '@/constants/common/storage.constants';
import {
  TCommonApiError,
  TCommonTokenResponse,
  TDecodedIDToken,
  TDecodedUserInfo,
} from '@/domains/common';
import {
  TUserLoginAuthenticatedResponse,
  TUserLoginBasicUserLoginResponse,
} from '@/domains/user-management/user';
import {
  EUserAuthStatus,
  EUserAuthType,
  EUserErrorType,
  EUserPlatformType,
} from '@/domains/user-management/user/user.constants';
import { mixpanel } from '@/mixpanel';
import { useAuthStore } from '@/store';

import Http from './axios.config';

import ApiServices from '.';

const cookies = new Cookies();

const credentialCookies = kStorageKey.Cookies;

class MarineApi {
  atlas: Http;
  barging: Http;

  constructor() {
    this.atlas = new Http(
      {
        baseURL: Env.API_ATLAS_URL,
        headers: {
          Authorization: `Bearer ${cookies.get(credentialCookies.IDToken)}`,
        },
      },
      { onError: (err) => this.onError(err) }
    );
    this.barging = new Http(
      {
        baseURL: Env.API_BARGING_URL,
        headers: {
          Authorization: `Bearer ${cookies.get(credentialCookies.IDToken)}`,
        },
      },
      { onError: (err) => this.onError(err) }
    );
  }

  updateAuthorizationToken(token: string | null) {
    if (token) {
      marineApi.atlas.updateConfig({
        headers: { Authorization: `Bearer ${token}` },
      });
      marineApi.barging.updateConfig({
        headers: { Authorization: `Bearer ${token}` },
      });
    }
  }

  setCredential({
    token,
    authStatus,
    authResult,
    cargoOwnerName,
  }: {
    token: TCommonTokenResponse;
    authStatus: EUserAuthStatus;
    authResult: unknown;
    cargoOwnerName?: string;
  }) {
    const uiToken = cookies.get(credentialCookies.UserInfoToken);
    const existingUserInfo = uiToken
      ? (jwtDecode(uiToken) as TDecodedUserInfo)
      : null;
    const decodedIDToken: TDecodedIDToken = jwtDecode(token.id_token);
    const userInfo = {
      cargo_owner_id: decodedIDToken.cargo_owner_id,
      cargo_owner_name: cargoOwnerName || existingUserInfo?.cargo_owner_name,
      'custom:roles': decodedIDToken['custom:roles'],
      email_verified: decodedIDToken.email_verified,
      'custom:group': decodedIDToken['custom:group'],
      'cognito:username': decodedIDToken['cognito:username'],
      auth_time: decodedIDToken.auth_time,
      name: decodedIDToken.name,
      nickname: decodedIDToken.nickname,
      phone_number: decodedIDToken.phone_number,
      exp: decodedIDToken.exp,
      accesses: JSON.stringify(
        (authResult as TUserLoginAuthenticatedResponse).accesses || ''
      ),
      iat: decodedIDToken.iat,
      email: decodedIDToken.email,
      auth_status: authStatus,
      cargo_owners:
        (authResult as TUserLoginBasicUserLoginResponse).cargo_owners || [],
    };

    mixpanel.identify(userInfo?.cargo_owner_id);

    mixpanel.people.set({
      $name: userInfo?.cargo_owner_name,
      $email: userInfo?.email,
    });

    const userInfoToken = jwtEncode(userInfo, Env.JWT_SECRET);

    cookies.set(
      credentialCookies.AccessToken,
      token.access_token,
      configCookiesOptions
    );
    cookies.set(
      credentialCookies.IDToken,
      token.id_token,
      configCookiesOptions
    );
    cookies.set(
      credentialCookies.RefreshToken,
      token.refresh_token,
      configCookiesOptions
    );
    cookies.set(
      credentialCookies.UserInfoToken,
      userInfoToken,
      configCookiesOptions
    );
    this.updateAuthorizationToken(token.id_token);
    return userInfo;
  }

  removeCredential() {
    cookies.remove(credentialCookies.AccessToken, configCookiesOptions);
    cookies.remove(credentialCookies.UserInfoToken, configCookiesOptions);
    cookies.remove(credentialCookies.IDToken, configCookiesOptions);
    cookies.remove(credentialCookies.RefreshToken, configCookiesOptions);
    useAuthStore.setState((state) => ({
      ...state,
      isAuthenticated: false,
      userInfo: undefined,
    }));
    this.updateAuthorizationToken(null);
  }

  async onError(error: AxiosError<TCommonApiError>) {
    const { config } = error;
    if (!config) return Promise.reject(error);

    const data = error.response?.data;

    // * Auto-logout will be handled on api.hooks.ts

    // #region Token Expired Check and Refresh
    if (data?.error?.type === EUserErrorType.TokenExpired) {
      return await ApiServices.userManagementUser
        .refreshToken({
          auth_type: EUserAuthType.RefreshToken,
          platform_type: EUserPlatformType.CargoOwnerDashboard,
          auth_parameters: {
            id_token: cookies.get(credentialCookies.IDToken),
          },
        })
        .then(({ data: res }) => {
          let token = res.auth_result.final_token;

          if (res.auth_status === EUserAuthStatus.ChooseCargoOwner) {
            token = res.auth_result.authentication_token;
          }

          this.setCredential({
            token,
            authResult: res.auth_result,
            authStatus: res.auth_status,
          });

          this.updateAuthorizationToken(token.id_token);
          config.headers.Authorization = `Bearer ${token.id_token}`;
          return axios.request(config);
        })
        .catch(() => {
          this.removeCredential();
          MTToast({
            content: 'Token Expired, Please Login Again!',
            type: 'danger',
          });
          window.location.reload();
        });
    }

    return Promise.reject(error);
  }
}

const marineApi = new MarineApi();

export default marineApi;
