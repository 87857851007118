import { SortOrder } from 'antd/es/table/interface';

import { ETableOrderBy } from '@/domains/common';

export const kUseTableOrderDictionary: Record<
  Exclude<SortOrder, null>,
  ETableOrderBy
> = {
  ascend: ETableOrderBy.ASC,
  descend: ETableOrderBy.DESC,
};

export const kUseTableReverseOrderDictionary: Record<
  ETableOrderBy,
  Exclude<SortOrder, null>
> = {
  [ETableOrderBy.ASC]: 'ascend',
  [ETableOrderBy.DESC]: 'descend',
};
