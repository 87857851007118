import { ConfigProvider } from 'antd';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { antdTheme } from '@/configs/antd/antd.config';

import Router from './routes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
});

const App: React.FC = () => {
  return (
    <CookiesProvider>
      <HelmetProvider>
        <ConfigProvider theme={antdTheme}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools />
            <Router />
          </QueryClientProvider>
        </ConfigProvider>
      </HelmetProvider>
    </CookiesProvider>
  );
};

export default App;
