import { lazy, Suspense } from 'react';

import MTErrorBoundary from '../mt-error-boundary';
import MTSpinner from '../mt-spinner';

export function MTLazyComponent<Props>(
  path: () => Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: React.ComponentType<any>;
  }>,
  loader?: JSX.Element | string
) {
  const LazyComponent = lazy(path);

  return function HOCMTLazyComponent({
    hocLoader,
    ...props
  }: React.PropsWithChildren<Props> & { hocLoader?: JSX.Element | string }) {
    return (
      <MTErrorBoundary>
        {/**
         * Since we also need a custom loader from MTLazyComponent, there will be loader for
         * lazy loading the component, and HOCLoader for lazy loading route.
         */}
        <Suspense fallback={loader || hocLoader || <MTSpinner />}>
          <LazyComponent {...props} />
        </Suspense>
      </MTErrorBoundary>
    );
  };
}

function MTLazyLoad(
  path: () => Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: React.ComponentType<any>;
  }>,
  loader?: JSX.Element | string
) {
  const LazyElement = MTLazyComponent(path);

  return <LazyElement hocLoader={loader} />;
}

export default MTLazyLoad;
