import { MTLazy } from '@/components';
import Endpoints from '@/configs/router';
import { AppRouter, TEndpoints } from '@/configs/router/router.types';
import { RouterKey } from '@/domains/common';
import { MTLayoutCMSLoader } from '@/layouts/cms';
import { MTLayoutCMSMultipleSearchbarLoader } from '@/layouts/cms-multiple-searchbar';

const RoutesDocumentCollection = {
  [Endpoints.DocumentCollectionList]: {
    name: 'Document Collection',
    path: Endpoints.DocumentCollectionList,
    element: MTLazy(
      () => import('@/pages/document-collection'),
      <MTLayoutCMSMultipleSearchbarLoader />
    ),
    mode: 'private',
    routerKey: RouterKey.DOCUMENT_COLLECTION_VIEW,
    asFirst: true,
  },
  [Endpoints.DocumentCollectionDetail]: {
    name: 'Document Collection Detail',
    path: Endpoints.DocumentCollectionDetail,
    element: MTLazy(
      () => import('@/pages/document-collection/detail'),
      <MTLayoutCMSLoader />
    ),
    mode: 'private',
    routerKey: RouterKey.DOCUMENT_COLLECTION_VIEW,
  },
} as Record<TEndpoints, AppRouter>;

export default RoutesDocumentCollection;
