import { ICODanger, ICOTick, ICOInfo, ICOWarningTriangle } from '@/assets/ico';
import { TComponentVariantColor } from '@/domains/common/component/component.types';

import './toastIconDictionary.constants.scss';

export const kToastIconDictionary: Record<
  Exclude<TComponentVariantColor, 'atlas' | 'default'>,
  React.ReactElement
> = {
  danger: <ICODanger className="mt-toast-icon" />,
  info: <ICOInfo className="mt-toast-icon" />,
  success: <ICOTick className="mt-toast-icon" />,
  warning: <ICOWarningTriangle className="mt-toast-icon" />,
};
