import { forwardRef, useMemo } from 'react';

import cx from 'classnames';

import type { MTCardProps } from './MTCard.types';
import './style.scss';

const MTCard = forwardRef<HTMLDivElement, MTCardProps>(
  (
    {
      borderRadius = 'sm',
      block = false,
      children,
      header,
      className,
      headerClassName,
      noShadow = false,
      padding,
      ...rest
    },
    ref
  ) => {
    const calculatePadding = useMemo(() => {
      if (
        Array.isArray(padding) &&
        (padding?.[0] === padding?.[1] || !padding?.[1])
      )
        return padding?.[0];
      if (Array.isArray(padding))
        return `x-${padding?.[0]} mt-card--padding-y-${padding?.[1]}`;

      return padding;
    }, [padding]);

    return (
      <div
        ref={ref}
        className={cx(
          'mt-card',
          `mt-card--border-radius-${borderRadius}`,
          noShadow && 'mt-card--no-shadow',
          block && 'mt-card--block',
          padding && `mt-card--padding-${calculatePadding}`,
          className
        )}
        {...rest}>
        {header && (
          <div className={cx('mt-card__header', headerClassName)}>{header}</div>
        )}
        {children}
      </div>
    );
  }
);

MTCard.displayName = 'MTCard';

export default MTCard;
