
import ApiEndpoints from '@/domains/api-endpoints';
import { TCommonApiResponse } from '@/domains/common';
import {
  TGetDocumentCollectionDetailParams,
  TGetDocumentCollectionDetailResponse,
  TGetDocumentCollectionListParams,
  TGetDocumentCollectionListResponse,
} from '@/domains/document-collection';

import marineApi from '../services.config';

export default class DocumentCollectionApi {
  getDocumentCollectionListDetail(
    params: TGetDocumentCollectionDetailParams
  ): Promise<TCommonApiResponse<TGetDocumentCollectionDetailResponse>> {
    return marineApi.barging.get(
      ApiEndpoints.documentCollectionDetail.replace(':id', params.id)
    );
  }

  getDocumentCollectionList(
    params?: TGetDocumentCollectionListParams
  ): Promise<
    TCommonApiResponse<TGetDocumentCollectionListResponse, 'Pagination'>
  > {
    return marineApi.barging.get(ApiEndpoints.documentCollection, { params });
  }
}

