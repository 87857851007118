import { MTLazy } from '@/components';
import Endpoints from '@/configs/router';
import { AppRouter, TEndpoints } from '@/configs/router/router.types';
import { RouterKey } from '@/domains/common';
import { MTLayoutCMSLoader } from '@/layouts/cms';
import { MTLayoutCMSFormLoader } from '@/layouts/cms-form';
import { MTLayoutCMSUpdateLogLoader } from '@/layouts/cms-update-log';

const RoutesUserManagementRoleGroup = {
  [Endpoints.UserManagementRoleGroupList]: {
    name: 'Role Group Management RoleGroup List',
    path: Endpoints.UserManagementRoleGroupList,
    element: MTLazy(
      () => import('@/pages/user-management/role-group'),
      <MTLayoutCMSLoader />
    ),
    mode: 'private',
    routerKey: RouterKey.USER_ROLE_GROUP_VIEW,
    asFirst: true,
  },
  [Endpoints.UserManagementRoleGroupAdd]: {
    name: 'Role Group Management RoleGroup Add',
    path: Endpoints.UserManagementRoleGroupAdd,
    element: MTLazy(
      () => import('@/pages/user-management/role-group/form'),
      <MTLayoutCMSFormLoader />
    ),
    mode: 'private',
    routerKey: RouterKey.USER_ROLE_GROUP_CREATE,
  },
  [Endpoints.UserManagementRoleGroupEdit]: {
    name: 'Role Group Management RoleGroup Edit',
    path: Endpoints.UserManagementRoleGroupEdit,
    element: MTLazy(
      () => import('@/pages/user-management/role-group/form'),
      <MTLayoutCMSFormLoader />
    ),
    mode: 'private',
    routerKey: RouterKey.USER_ROLE_GROUP_EDIT,
  },

  // #region ROLE GROUP DETAIL PAGE
  [Endpoints.UserManagementRoleGroupDetail]: {
    name: 'User Role Group Detail',
    path: Endpoints.UserManagementRoleGroupDetail,
    element: MTLazy(
      () => import('@/pages/user-management/role-group/detail'),
      <MTLayoutCMSLoader />
    ),
    mode: 'private',
    routerKey: RouterKey.USER_ROLE_GROUP_VIEW,
  },
  [Endpoints.UserManagementRoleGroupInformation]: {
    name: 'User Role Group Information',
    path: Endpoints.UserManagementRoleGroupInformation,
    element: MTLazy(
      () => import('@/pages/user-management/role-group/detail/information'),
      <MTLayoutCMSLoader />
    ),
    mode: 'private',
    routerKey: RouterKey.USER_ROLE_GROUP_VIEW,
  },
  [Endpoints.UserManagementRoleGroupUpdateLog]: {
    name: 'Update Log',
    path: Endpoints.UserManagementRoleGroupUpdateLog,
    element: MTLazy(
      () => import('@/pages/user-management/role-group/detail/update-log'),
      <MTLayoutCMSUpdateLogLoader />
    ),
    mode: 'private',
    routerKey: RouterKey.USER_ROLE_GROUP_VIEW,
  },
  // #endregion
} as Record<TEndpoints, AppRouter>;

export default RoutesUserManagementRoleGroup;
