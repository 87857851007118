import { MTLazy } from '@/components';
import Endpoints from '@/configs/router';
import { AppRouter, TEndpoints } from '@/configs/router/router.types';
import { RouterKey } from '@/domains/common';
import { MTLayoutCMSLoader } from '@/layouts/cms';
import { MTLayoutCMSMultipleSearchbarLoader } from '@/layouts/cms-multiple-searchbar';

const RoutesMonitoring = {
  [Endpoints.BaseMonitoring]: {
    name: 'Shipment Monitoring',
    path: Endpoints.BaseMonitoring,
    element: MTLazy(() => import('@/pages/monitoring'), <MTLayoutCMSLoader />),
    mode: 'private',
    routerKey: RouterKey.MONITORING_VIEW,
    asFirst: true,
  },
  [Endpoints.ShipmentMonitoring]: {
    name: 'Shipment Monitoring',
    path: Endpoints.ShipmentMonitoring,
    element: MTLazy(
      () => import('@/pages/monitoring/vessel-tracker'),
      <MTLayoutCMSLoader />
    ),
    mode: 'private',
    routerKey: RouterKey.MONITORING_VIEW,
  },
  [Endpoints.MonitoringList]: {
    name: 'Monitoring',
    path: Endpoints.MonitoringList,
    element: MTLazy(
      () => import('@/pages/monitoring/shipment-tracker'),
      <MTLayoutCMSMultipleSearchbarLoader />
    ),
    mode: 'private',
    routerKey: RouterKey.MONITORING_VIEW,
  },
} as Record<TEndpoints, AppRouter>;

export default RoutesMonitoring;
