import ApiEndpoints from '@/domains/api-endpoints';
import { TGetUserInfoResponse } from '@/domains/auth/getUserInfo';
import { TCommonApiResponse } from '@/domains/common';

import marineApi from '../services.config';

export default class AuthApi {
  getUserInfo(): Promise<TCommonApiResponse<TGetUserInfoResponse>> {
    return marineApi.atlas.get(ApiEndpoints.userInfo);
  }
}
