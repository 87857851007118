import {
  QueryKey,
  UseBaseMutationResult,
  UseQueryResult,
  UseMutationOptions as UseReactMutationOptions,
  UseQueryOptions as UseReactQueryOptions,
  useMutation,
  useQuery,
} from 'react-query';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import * as components from '@/components';
import Endpoints from '@/configs/router';
import { kMixpanelEventNames } from '@/constants/common/mixpanel.constants';
import kStorageKey from '@/constants/common/storage.constants';
import {
  TCommonApiError,
  TCommonApiResponse,
} from '@/domains/common/common.types';
import { EUserErrorType } from '@/domains/user-management/user/user.constants';
import { mixpanel } from '@/mixpanel';
import marineApi from '@/services/services.config';

const cookies = new Cookies();

const userErrorTypeAutoLogout = [
  EUserErrorType.UserSuspended,
  EUserErrorType.UserLocked,
  EUserErrorType.CredentialRefreshed,
  EUserErrorType.UserHasNoRole,
  EUserErrorType.UserNotFound,
  EUserErrorType.TokenInvalid,
];

export type UseQueryOptions<Data = undefined, Error = TCommonApiError> = Omit<
  UseReactQueryOptions<Data, Error, Data, QueryKey>,
  'queryKey' | 'queryFn'
>;

export function useMTQuery<
  Data = undefined,
  Error = TCommonApiError,
  Type extends 'Basic' | 'Pagination' = 'Basic'
>(
  queryKey: QueryKey,
  callback: () => Promise<TCommonApiResponse<Data, Type>>,
  options?: UseQueryOptions<TCommonApiResponse<Data, Type>, Error> & {
    /**
     * To flag the query whether it's for authentication or not.
     *
     * If set to `false` and authentications error such as `UserSuspended`, `UserLocked`, `CredentialRefreshed`, `UserHasNoRole`, and `UserNotFound` happens,
     * the user will be auto-logout from the app.
     *
     * The default value is `false`.
     */
    isAuthentication?: boolean;
  }
): UseQueryResult<TCommonApiResponse<Data, Type>, Error> {
  const navigate = useNavigate();

  return useQuery(queryKey, callback, {
    ...options,
    onError: (err) => {
      const { error } = err as TCommonApiError;

      if (
        !options?.isAuthentication &&
        userErrorTypeAutoLogout.includes(error?.type as EUserErrorType)
      ) {
        {
          mixpanel.track(kMixpanelEventNames.LOGGED_OUT_AUTO);
          mixpanel.reset();

          marineApi.removeCredential();

          components.MTToast({
            content: error?.message,
            type: 'danger',
          });
          
          navigate(Endpoints.Login, { replace: true });
        }
      }

      options?.onError?.(err);
    },
    onSettled: () => {
      if (
        !cookies.get(kStorageKey.Cookies.IDToken) ||
        !cookies.get(kStorageKey.Cookies.RefreshToken)
      ) {
        mixpanel.track(kMixpanelEventNames.LOGGED_OUT_AUTO);
        mixpanel.reset();

        marineApi.removeCredential();

        navigate(Endpoints.Login, { replace: true });
      }
    },
  });
}

export type UseMutationOptions<
  Data = undefined,
  Params = undefined,
  Error = TCommonApiError
> = UseReactMutationOptions<Data, Error, Params>;

export function useMTMutation<
  Data = undefined,
  Params = never,
  Error = TCommonApiError,
  Type extends 'Basic' | 'Pagination' = 'Basic'
>(
  callback: (params: Params) => Promise<TCommonApiResponse<Data, Type>>,
  options?: UseMutationOptions<
    TCommonApiResponse<Data, Type>,
    Params,
    Error
  > & {
    /**
     * To flag the query whether it's for authentication or not.
     *
     * If set to `false` and authentications error such as `UserSuspended`, `UserLocked`, `CredentialRefreshed`, `UserHasNoRole`, and `UserNotFound` happens,
     * the user will be auto-logout from the app.
     *
     * The default value is `false`.
     */
    isAuthentication?: boolean;
  }
): UseBaseMutationResult<TCommonApiResponse<Data, Type>, Error, Params> {
  const navigate = useNavigate();
  return useMutation(callback, {
    ...options,
    onError: (err, variables, context) => {
      const { error } = err as TCommonApiError;

      if (
        !options?.isAuthentication &&
        userErrorTypeAutoLogout.includes(error?.type as EUserErrorType)
      ) {
        {
          mixpanel.track(kMixpanelEventNames.LOGGED_OUT_AUTO);
          mixpanel.reset();

          marineApi.removeCredential();

          components.MTToast({
            content: error?.message,
            type: 'danger',
          });

          navigate(Endpoints.Login, { replace: true });
        }
      }
      options?.onError?.(err, variables, context);
    },
  });
}
