import { MTLazy } from '@/components';
import Endpoints from '@/configs/router';
import { AppRouter, TEndpoints } from '@/configs/router/router.types';
import { RouterKey } from '@/domains/common';
import { MTLayoutCMSLoader } from '@/layouts/cms';
import { MTLayoutCMSFormLoader } from '@/layouts/cms-form';
import { MTLayoutCMSUpdateLogLoader } from '@/layouts/cms-update-log';

const RoutesUserManagementUser = {
  [Endpoints.UserManagementUserList]: {
    name: 'User Management User List',
    path: Endpoints.UserManagementUserList,
    element: MTLazy(
      () => import('@/pages/user-management/user'),
      <MTLayoutCMSLoader />
    ),
    mode: 'private',
    routerKey: RouterKey.USER_USER_VIEW,
    asFirst: true,
  },
  [Endpoints.UserManagementUserAdd]: {
    name: 'User Management User Add',
    path: Endpoints.UserManagementUserAdd,
    element: MTLazy(
      () => import('@/pages/user-management/user/form'),
      <MTLayoutCMSFormLoader />
    ),
    mode: 'private',
    routerKey: RouterKey.USER_USER_CREATE,
  },
  [Endpoints.UserManagementUserEdit]: {
    name: 'User Management User Edit',
    path: Endpoints.UserManagementUserEdit,
    element: MTLazy(
      () => import('@/pages/user-management/user/form'),
      <MTLayoutCMSFormLoader />
    ),
    mode: 'private',
  },

  // #region USER DETAIL PAGE
  [Endpoints.UserManagementUserDetail]: {
    name: 'User Detail',
    path: Endpoints.UserManagementUserDetail,
    element: MTLazy(
      () => import('@/pages/user-management/user/detail'),
      <MTLayoutCMSLoader />
    ),
    mode: 'private',
  },
  [Endpoints.UserManagementUserInformation]: {
    name: 'User Information',
    path: Endpoints.UserManagementUserInformation,
    element: MTLazy(
      () => import('@/pages/user-management/user/detail/information'),
      <MTLayoutCMSLoader />
    ),
    mode: 'private',
  },
  [Endpoints.UserManagementUserUpdateLog]: {
    name: 'Update Log',
    path: Endpoints.UserManagementUserUpdateLog,
    element: MTLazy(
      () => import('@/pages/user-management/user/detail/update-log'),
      <MTLayoutCMSUpdateLogLoader />
    ),
    mode: 'private',
  },
  // #endregion
} as Record<TEndpoints, AppRouter>;

export default RoutesUserManagementUser;
