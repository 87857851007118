export const kMixpanelEventNames = {
  // #region
  SUSPENDED_VIEWED: 'Suspended Account Page Viewed',
  NO_ACTIVE_USER_VIEWED: 'No Active User Role Group Page Viewed',
  SELECT_COMPANY_VIEWED: 'Select Co-company Page Viewed',
  // #endregion

  // #region
  LOGIN_VIEWED: 'Login Page Viewed',
  LOGGED_IN_SUCCESS: 'Success Logged in',
  LOGGED_IN_FAILED: 'Failed Logged in',
  // #endregion

  // #region
  LOGGED_OUT_MANUAL: 'Manual Signed Out',
  LOGGED_OUT_AUTO: 'Auto Signed Out',
  // #endregion

  // #region
  SHIPMENT_TABLE_VIEWED: 'Shipment Table Page Viewed',
  SHIPMENT_TABLE_SEARCHED: 'Shipment Table Searched',
  SHIPMENT_TABLE_FILTERED: 'Shipment Table Filtered',
  SHIPMENT_TABLE_SORTED: 'Shipment Table Sorted',
  SHIPMENT_DETAIL_INFORMATION_VIEWED:
    'Shipment Information - Detail Page Viewed',
  SHIPMENT_DETAIL_INFORMATION_DOCUMENT_VIEWED:
    'Shipment Information - Document Viewed',
  SHIPMENT_DETAIL_INFORMATION_DOCUMENT_DOWNLOAD:
    'Shipment Information - Document Downloaded',
  SHIPMENT_DETAIL_UPDATE_LOG_VIEWED: 'Shipment Update Log Page Viewed',
  SHIPMENT_DETAIL_UPDATE_LOG_CHANGE_VIEWED:
    'Shipment Update Log Changes Viewed',
  // #endregion

  // #region
  MONITORING_VESSEL_TRACKER_VIEWED: 'Monitoring - Vessel Tracker Page Viewed',
  MONITORING_VESSEL_TRACKER_SEARCHED: 'Monitoring - Vessel Tracker Searched',
  MONITORING_VESSEL_TRACKER_TOOLTIP_CLICKED:
    'Vessel Tracker - Vessel Tooltip Clicked',
  MONITORING_SHIPMENT_TRACKER_VIEWED:
    'Monitoring - Shipment Tracker Page Viewed',
  MONITORING_SHIPMENT_TRACKER_SEARCHED:
    'Monitoring - Shipment Tracker Table Searched',
  MONITORING_SHIPMENT_TRACKER_FILTERED:
    'Monitoring - Shipment Tracker Table Filtered',
  MONITORING_SHIPMENT_TRACKER_SORTED:
    'Monitoring - Shipment Tracker Table Sorted',
  // #endregion

  // #region
  DOCUMENT_COLLECTIONS_VIEWED: 'Document Collection Page Viewed',
  DOCUMENT_COLLECTIONS_SEARCHED: 'Document Collection Table Searched',
  DOCUMENT_COLLECTIONS_FILTERED: 'Document Collection Table Filtered',
  DOCUMENT_COLLECTIONS_SORTED: 'Document Collection Table Sorted',
  DOCUMENT_COLLECTIONS_DETAIL_VIEWED: 'Document Collection Detail Page Viewed',
  DOCUMENT_COLLECTIONS_DETAIL_DOCUMENT_VIEWED:
    'Document Collection - Document Viewed',
  DOCUMENT_COLLECTIONS_DETAIL_DOCUMENT_DOWNLOAD:
    'Document Collection - Document Downloaded',
  // #endregion

  // #region
  USER_LIST_VIEWED: 'User List Page Viewed',
  USER_LIST_SEARCHED: 'User List Table Searched',
  USER_LIST_FILTERED: 'User List Table Filtered',
  USER_DETAIL_VIEWED: 'User Detail Page Viewed',
  USER_DETAIL_UPDATE_LOG_VIEWED: 'User List Update Log Page Viewed',
  USER_DETAIL_UPDATE_LOG_CHANGE_VIEWED: 'User List Update Log Changes Viewed',
  // #endregion

  // #region
  USER_ROLE_GROUP_VIEWED: 'User Role Group Page Viewed',
  USER_ROLE_GROUP_SEARCHED: 'User Role Group Table Searched',
  USER_ROLE_GROUP_FILTERED: 'User Role Group Table Filtered',
  USER_ROLE_GROUP_SORTED: 'User Role Group Table Sorted',
  USER_ROLE_GROUP_DETAIL_VIEWED: 'User Detail Role Group Page Viewed',
  USER_ROLE_GROUP_DETAIL_FILTERED: 'User Role Group Detail Filtered',
  USER_ROLE_GROUP_DETAIL_UPDATE_LOG_VIEWED:
    'User Role Group Update Log Page Viewed',
  USER_ROLE_GROUP_DETAIL_UPDATE_LOG_CHANGE_VIEWED:
    'User Role Group Update Log Changes Viewed',
  // #endregion

  // #region
  CREATE_USER_VIEWED: 'New User Page Viewed',
  CREATE_USER_SUCCESS: 'New User Created Success',
  CREATE_USER_FAILED: 'New User Created Failed',
  EDIT_USER_VIEWED: 'Edit User Page Viewed',
  EDIT_USER_SUCCESS: 'Edit User Created Success',
  EDIT_USER_FAILED: 'Edit User Created failingly',
  // #endregion

  // #region
  CREATE_ROLE_GROUP_VIEWED: 'New User Role Group Viewed',
  CREATE_ROLE_GROUP_SUCCESS: 'New User Role Group Created successfully',
  CREATE_ROLE_GROUP_FAILED: 'New User Role Group Created failingly',
  EDIT_ROLE_GROUP_VIEWED: 'Edit User Role Group Viewed',
  EDIT_ROLE_GROUP_SUCCESS: 'Edit User Role Group Created successfully',
  EDIT_ROLE_GROUP_FAILED: 'Edit User Role Group Created failingly',
  // #endregion
} as const;

export const kMixpanelPropertyNames = {
  // #region
  EMAIL_ADDRESS: 'Email Address',
  FILE_NAME: 'File Name',
  MESSAGE: 'Message',
  DOWNLOAD_TIME: 'File Downloaded Date and Time',
  SHIPMENT_NUMBER: 'Shipment Number',
  CARGO_OWNER_NAME: 'Cargo Owner Name',
  USER_ROLE_GROUP: 'User Role Group',
  // #endregion
} as const;
