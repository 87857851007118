import { Layout } from 'antd';

import { MTHeader, MTSider } from '@/components';

import './style.scss';

const MTLayoutCMSLoader: React.FC = () => {
  return (
    <Layout className="mt-layout-cms">
      <MTHeader />
      <Layout hasSider>
        <MTSider />
        <Layout className="mt-layout-cms__inner">
          <div className="mt-layout-cms__main"></div>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MTLayoutCMSLoader;
