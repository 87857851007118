import ApiEndpoints from '@/domains/api-endpoints';
import {
  TApiNameResponseData,
  TCommonApiResponse,
} from '@/domains/common/common.types';
import {
  TCreateUserListParams,
  TGetUserDetailParams,
  TGetUserDetailResponse,
  TGetUserListParams,
  TGetUserListResponse,
  TGetUserUpdateLogParams,
  TGetUserUpdateLogResponse,
  TUpdateUserListParams,
  TUserChooseCargoOwnerCompany,
  TUserChooseCargoOwnerCompanyResponse,
  TUserForgotPasswordCheckURLPayload,
  TUserForgotPasswordCheckURLResponse,
  TUserForgotPasswordPayload,
  TUserForgotPasswordSubmitPayload,
  TUserForgotPasswordVerifyPayload,
  TUserForgotPasswordVerifyResponse,
  TUserGetCargoOwnerCompanies,
  TUserLoginAuthenticatedResponse,
  TUserLoginBasicUserLoginResponse,
  TUserLoginCommonPayload,
  TUserLoginCommonResponse,
  TUserLoginForceChangePasswordResponse,
  TUserLoginRefreshToken,
  TUserLoginWithCode,
  TUserLoginWithEmail,
  TUserLoginWithNewPassword,
  TUserReactivateAccountParams,
  TUserRemoveAccountParams,
  TUserResendInvitationParams,
} from '@/domains/user-management/user';
import {
  TCheckUserIsExistParams,
  TCheckUserIsExistResponse,
} from '@/domains/user-management/user/checkUserIsExist';
import marineApi from '@/services/services.config';

export default class UserManagementUserApi {
  chooseCargoOwnerCompany(
    payload: TUserChooseCargoOwnerCompany
  ): Promise<
    TCommonApiResponse<
      TUserLoginCommonResponse<TUserChooseCargoOwnerCompanyResponse>
    >
  > {
    return marineApi.atlas.post(ApiEndpoints.chooseCargoOwnerCompany, payload);
  }

  getCargoOwnerCompanies(
    params?: TUserGetCargoOwnerCompanies
  ): Promise<TCommonApiResponse<TApiNameResponseData[]>> {
    return marineApi.barging.get(ApiEndpoints.getCargoOwnersCompanies, {
      params,
    });
  }

  login(
    payload: TUserLoginCommonPayload<TUserLoginWithEmail>
  ): Promise<
    TCommonApiResponse<
      TUserLoginCommonResponse<
        TUserLoginBasicUserLoginResponse & TUserLoginAuthenticatedResponse
      >
    >
  > {
    return marineApi.atlas.post(ApiEndpoints.login, payload);
  }

  refreshToken(
    payload: TUserLoginCommonPayload<TUserLoginRefreshToken>
  ): Promise<
    TCommonApiResponse<
      TUserLoginCommonResponse<
        TUserLoginBasicUserLoginResponse & TUserLoginAuthenticatedResponse
      >
    >
  > {
    return marineApi.atlas.post(ApiEndpoints.login, payload);
  }

  verifyUserActivation(
    payload: TUserLoginCommonPayload<TUserLoginWithCode>
  ): Promise<
    TCommonApiResponse<
      TUserLoginCommonResponse<TUserLoginForceChangePasswordResponse>
    >
  > {
    return marineApi.atlas.post(ApiEndpoints.login, payload);
  }

  setNewPassword(
    payload: TUserLoginCommonPayload<TUserLoginWithNewPassword>
  ): Promise<
    TCommonApiResponse<
      TUserLoginCommonResponse<TUserLoginAuthenticatedResponse>
    >
  > {
    return marineApi.atlas.post(ApiEndpoints.login, payload);
  }

  getUserList(
    params?: TGetUserListParams
  ): Promise<TCommonApiResponse<TGetUserListResponse, 'Pagination'>> {
    return marineApi.atlas.get(ApiEndpoints.users, { params });
  }

  createUserList(params: TCreateUserListParams): Promise<TCommonApiResponse> {
    return marineApi.atlas.post(ApiEndpoints.users, params);
  }

  updateUserList({
    id,
    ...params
  }: TUpdateUserListParams): Promise<TCommonApiResponse> {
    return marineApi.atlas.put(
      ApiEndpoints.userDetail.replace(':id', id),
      params
    );
  }

  getUserDetail({
    id,
    ...params
  }: TGetUserDetailParams): Promise<
    TCommonApiResponse<TGetUserDetailResponse>
  > {
    return marineApi.atlas.get(ApiEndpoints.userDetail.replace(':id', id), {
      params,
    });
  }

  getUserUpdateLog({
    id,
    ...params
  }: TGetUserUpdateLogParams): Promise<
    TCommonApiResponse<TGetUserUpdateLogResponse, 'Pagination'>
  > {
    return marineApi.atlas.get(ApiEndpoints.userUpdateLog.replace(':id', id), {
      params,
    });
  }

  removeUserAccount(
    params: TUserRemoveAccountParams
  ): Promise<TCommonApiResponse<null>> {
    return marineApi.atlas.delete(
      ApiEndpoints.userDetail.replace(':id', params.id)
    );
  }

  reactivateUserAccount(
    params: TUserReactivateAccountParams
  ): Promise<TCommonApiResponse<null>> {
    return marineApi.atlas.post(
      ApiEndpoints.reactivateAccount.replace(':id', params.id),
      {}
    );
  }

  resendInvitation(
    params: TUserResendInvitationParams
  ): Promise<TCommonApiResponse<null>> {
    return marineApi.atlas.post(
      ApiEndpoints.resendInvitation.replace(':id', params.id),
      {}
    );
  }

  requestForgotPasswordCode(
    params: TUserForgotPasswordPayload
  ): Promise<TCommonApiResponse<null>> {
    return marineApi.atlas.post(ApiEndpoints.requestForgotPasswordCode, params);
  }

  checkForgotPasswordURL(
    params: TUserForgotPasswordCheckURLPayload
  ): Promise<TCommonApiResponse<TUserForgotPasswordCheckURLResponse>> {
    return marineApi.atlas.post(ApiEndpoints.checkURLForgotPassword, params);
  }

  verifyForgotPassword(
    params: TUserForgotPasswordVerifyPayload
  ): Promise<TCommonApiResponse<TUserForgotPasswordVerifyResponse>> {
    return marineApi.atlas.post(ApiEndpoints.verifyForgotPassword, params);
  }

  submitForgotPassword(
    params: TUserForgotPasswordSubmitPayload
  ): Promise<TCommonApiResponse<null>> {
    return marineApi.atlas.post(ApiEndpoints.submitForgotPassword, params);
  }

  checkUserIsExist(
    params: TCheckUserIsExistParams
  ): Promise<TCommonApiResponse<TCheckUserIsExistResponse>> {
    return marineApi.atlas.post(ApiEndpoints.userIsExist, params);
  }
}
