import { Component, ErrorInfo } from 'react';

import * as Sentry from '@sentry/react';

import type {
  MTErrorBoundaryProps,
  MTErrorBoundaryState,
} from './MTErrorBoundary.types';
import './style.scss';

const initState = { error: null, errorInfo: null };
class MTErrorBoundary extends Component<
  MTErrorBoundaryProps,
  MTErrorBoundaryState
> {
  public state: MTErrorBoundaryState = initState;

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (error.toString().toLowerCase().includes('dynamically imported')) {
      return window.location.reload();
    }
    this.setState({ error, errorInfo });

    Sentry.withScope((scope) => {
      scope.setExtras({ errorInfo: errorInfo.componentStack });
      Sentry.captureException(error);
    });
  }

  public render() {
    if (this.state.error && this.state.errorInfo) {
      if (this.props.fallbackElement)
        return this.props.fallbackElement({
          error: this.state.error,
          errorInfo: this.state.errorInfo,
          resetError: () => {
            this.setState(initState);
            this.props.onRefresh?.();
          },
        });

      // Error path
      return (
        <div className="mt-error-boundary">
          <h3 className="mt-error-boundary__title">
            {this.state.error && this.state.error.toString()}
          </h3>
          <details className="mt-error-boundary__details">
            <pre className="mt-error-boundary__error-stack">
              {this.state.errorInfo.componentStack}
            </pre>
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

export default MTErrorBoundary;
