import { useState } from 'react';

import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { useUpdateEffect } from 'usehooks-ts';

import type { TViewerOperations } from '@/domains/common/component/component.types';
import { useMixpanel } from '@/hooks';

import { useViewerCore } from '../core/ViewerCore.hooks';

import type { IUseMTViewerPDF } from './MTViewerPDF.types';

export const useMTViewerPDF = ({
  onClose,
  isOpen,
  PDFPreviewRef,
  PDFRef,
  PDFWrapRef,
  ViewerMaskRef,
  ViewerOperationsRef,
  src,
}: IUseMTViewerPDF) => {
  // #region states
  const [scale, setScale] = useState<number>(1);
  const [rotate, setRotate] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isShowDOM, setIsShowDOM] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pdfPosition, setPdfPosition] = useViewerCore({
    elementRef: PDFRef,
    wrapRef: PDFWrapRef,
  });
  // #endregion

  const { mixpanelTrackDownloadDocs } = useMixpanel();

  // #region operations
  const handleClose = () => {
    onClose?.();
  };

  const handleZoom = (action: 'in' | 'out') => {
    if (action === 'out' && scale > 1) return setScale((prev) => prev - 0.5);
    if (action === 'in' && scale < 5) return setScale((prev) => prev + 0.5);
  };

  const handleRotate = (action: 'right' | 'left') => {
    if (action === 'left') return setRotate(rotate - 90);
    return setRotate(rotate + 90);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.download = src;
    link.href = src;
    link.target = '_blank';
    link.click();

    mixpanelTrackDownloadDocs({ src });
  };

  const handlePagination = (action: 'next' | 'prev') => {
    if (action === 'prev' && currentPage > 0)
      return setCurrentPage((prev) => prev - 1);
    if (action === 'next' && currentPage < totalPages - 1)
      return setCurrentPage((prev) => prev + 1);
  };
  // #endregion

  const onDocumentLoadSuccess = (docTotalPages: number) => {
    setTotalPages(docTotalPages);
  };

  // #region event listener handler
  const handleScrollWheelPDF = (e: React.WheelEvent<HTMLImageElement>) => {
    const delta = e.nativeEvent?.deltaY;
    const isDown = delta > 0;

    handleZoom(isDown ? 'out' : 'in');
  };
  // #endregion

  // #region animation
  const animation = () => {
    if (isOpen) {
      PDFWrapRef.current!.style.display = '';
      setIsShowDOM(true);
      document.body.style.overflowY = 'hidden';
      document.body.style.width = 'calc(100% - 15px)';
      PDFPreviewRef.current?.classList.add('mt-zoom', 'mt-zoom-in');

      PDFPreviewRef.current!.onanimationend = () => {
        ViewerMaskRef.current?.classList.remove('mt-fade', 'mt-fade-in');
        ViewerOperationsRef.current?.classList.remove('mt-fade', 'mt-fade-in');
        PDFPreviewRef.current?.classList.remove('mt-zoom', 'mt-zoom-in');
      };
    } else {
      PDFPreviewRef.current?.classList.add('mt-zoom', 'mt-zoom-out');
      ViewerMaskRef.current?.classList.add('mt-fade', 'mt-fade-out');
      ViewerOperationsRef.current?.classList.add('mt-fade', 'mt-fade-out');
      PDFPreviewRef.current!.onanimationend = () => {
        PDFPreviewRef.current?.classList.remove('mt-zoom', 'mt-zoom-out');
        document.body.style.width = '';
        document.body.style.overflowY = '';
        setIsShowDOM(false);
        PDFWrapRef.current!.style.display = 'none';
        setScale(1);
        setRotate(0);
        setCurrentPage(0);
        setPdfPosition({
          lastX: 0,
          lastY: 0,
          startX: 0,
          startY: 0,
          x: 0,
          y: 0,
        });
      };
    }
  };
  // #endregion

  // #region side effect
  useUpdateEffect(() => {
    animation();
  }, [isOpen]);

  // #endregion

  const iconClassName = 'mt-viewer-operations__operation__icon';

  const viewerOperations: TViewerOperations[] = [
    {
      name: 'ZOOM_IN',
      onClick: () => handleZoom('in'),
      icon: <ZoomInOutlined className={iconClassName} />,
      disabled: scale >= 5,
    },
    {
      name: 'ZOOM_OUT',
      onClick: () => handleZoom('out'),
      icon: <ZoomOutOutlined className={iconClassName} />,
      disabled: scale <= 1,
    },
    {
      name: 'ROTATE_RIGHT',
      onClick: () => handleRotate('right'),
      icon: <RotateRightOutlined className={iconClassName} />,
    },
    {
      name: 'ROTATE_LEFT',
      onClick: () => handleRotate('left'),
      icon: <RotateLeftOutlined className={iconClassName} />,
    },
    {
      name: 'DOWNLOAD',
      onClick: handleDownload,
      icon: <DownloadOutlined className={iconClassName} />,
    },
  ];

  return {
    currentPage,
    handleClose,
    handlePagination,
    handleScrollWheelPDF,
    isShowDOM,
    onDocumentLoadSuccess,
    pdfPosition,
    rotate,
    scale,
    totalPages,
    viewerOperations,
  };
};
