import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import config from '@/configs/environment.config';

export const initSentry = () => {
  const sentryDsn = config.SENTRY_DNS;

  const traceRate = {
    DEV: 0.1, // Sample 10% of traces in development
    STG: 0.5, // Sample 50% of traces in staging
    PRD: 1, // Sample all traces in production
  };

  const replaysSessionRate = {
    DEV: 0, // Disable session replay in development
    STG: 0.2, // 20% of sessions in staging
    PRD: 0.1, // 10% of sessions in production
  };

  if (!sentryDsn) return; // Exit early if Sentry DSN is not provided

  const environment = config.ENVIRONMENT;
  const tracingRate = traceRate[environment] || 0;
  const sessionReplayRate = replaysSessionRate[environment] || 0;

  Sentry.init({
    dsn: sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: tracingRate,
    replaysSessionSampleRate: sessionReplayRate,
    replaysOnErrorSampleRate: 1.0, // Always replay on error
  });
};

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
