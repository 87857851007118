import { create } from 'zustand';

import kStorageKey from '@/constants/common/storage.constants';

import type { useCommonStoreProps } from './common.types';

const useCommonStore = create<useCommonStoreProps>((set) => ({
  // 'null' is used instead of null is because when JSON.parse can't get anything, it will fallback to null.

  // #region isSidebarCollapsed
  isSidebarCollapsed:
    JSON?.parse(
      localStorage?.getItem(kStorageKey.SidebarCollapsible) || 'null'
    ) || false,
  updateIsSidebarCollapsed: (value) =>
    set((state) => {
      localStorage.setItem(kStorageKey.SidebarCollapsible, value?.toString());
      return { ...state, isSidebarCollapsed: value };
    }),
  // #endregion
}));

export default useCommonStore;
