import { useEffect } from 'react';

import {
  matchRoutes,
  unstable_useBlocker,
  useNavigate as useRouterNavigate,
} from 'react-router-dom';

import { TEndpoints } from '@/configs/router/router.types';
import RouterList from '@/routes/routes';

export const PROMPT_INIT_MESSAGE =
  'Are you sure you want to leave this page? Change will not be saved.';

const routes = RouterList.map((router) => ({ path: router.path as string }));

export default function useNavigate(props?: {
  block: boolean;
  stricts?: Partial<Record<'POP' | 'PUSH' | 'REPLACE', TEndpoints[] | boolean>>;
  excludes?: Partial<
    Record<'POP' | 'PUSH' | 'REPLACE', TEndpoints[] | boolean>
  >;
}) {
  const routeNavigate = useRouterNavigate();

  useEffect(() => {
    if (props?.block) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
    return () => {
      window.onbeforeunload = () => undefined;
    };
  }, [props?.block]);

  unstable_useBlocker(({ nextLocation, historyAction }) => {
    const match = matchRoutes(routes, nextLocation.pathname);
    const strictsValue = props?.stricts?.[historyAction];
    const excludesValue = props?.excludes?.[historyAction];
    if (
      // block without stricts options
      (props?.block && !props?.stricts) ||
      // block with boolean stricts options
      (props?.block && typeof strictsValue === 'boolean' && strictsValue) ||
      // block with array stricts options
      (props?.block &&
        Array.isArray(strictsValue) &&
        match?.[0].route.path &&
        strictsValue.includes(match?.[0].route.path as TEndpoints)) ||
      // block everything excluding certain action
      (props?.block && typeof excludesValue === 'boolean' && !excludesValue) ||
      // block everything excluding certain endpoints
      (props?.block &&
        Array.isArray(excludesValue) &&
        match?.[0].route.path &&
        !excludesValue.includes(match?.[0].route.path as TEndpoints))
    ) {
      const isConfirmed = confirm(PROMPT_INIT_MESSAGE);
      return !isConfirmed;
    }
    return false;
  });

  return routeNavigate;
}
