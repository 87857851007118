import cx from 'classnames';

import '@/scss/utils/_borderRadius.scss';

import type { MTChipProps } from '.';
import './style.scss';

const borderRadiusDict: Record<
  NonNullable<MTChipProps['borderRadius']>,
  string
> = {
  none: 'radius--none',
  rounded: 'radius--circle',
  sm: 'radius--0',
  md: 'radius--1',
  lg: 'radius--2',
};

const MTChip: React.FC<MTChipProps> = ({
  borderRadius = 'rounded',
  children,
  className,
  color = 'gray',
  ...rest
}) => {
  return (
    <span
      className={cx(
        'mt-chip',
        borderRadiusDict?.[borderRadius],
        `mt-chip--color--${color}`,
        className
      )}
      {...rest}>
      {children}
    </span>
  );
};

export default MTChip;
