export const kRegex = {
  BackslashFile: /(\\|\/)/g,
  PhoneNumberFormat: /^[0-9]{10}$/,
  NumbersOnly: /^\d+$/,
  EmailFormat: /^\S+@\S+\.\S+$/,
  AtLeastOneNonNumber: /[^0-9]/,
  AtLeastOneLowerCase: /[a-z]/,
  AtLeastOneUpperCase: /[A-Z]/,
  AtleastOneNumber: /[0-9]+/,
  AtleastOneSpecialChar: /[!@#$%^&*(),.?":{}|<>]+/,
  HasBothLowerAndUpperCase: /([A-Z].*[a-z]|[a-z].*[A-Z])/,
  ImageFile: /\.(jpe?g|png|gif)$/,
  GetCargoOwnerIDFromCode: /(.*:)(.*)/,
} as const;
