import React from 'react';

import jwtDecode from 'jwt-decode';
import { useQueryClient } from 'react-query';
import Cookies from 'universal-cookie';
import { useEventListener } from 'usehooks-ts';

import kStorageKey from '@/constants/common/storage.constants';
import marineApi from '@/services/services.config';
import { useAuthStore } from '@/store';

const cookies = new Cookies();

const MTLayoutBase: React.FC<React.PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const { updateUserInfo } = useAuthStore();

  useEventListener('focus', () => {
    const cookiesIDToken = cookies.get(kStorageKey.Cookies.IDToken);
    const configIDToken = (
      marineApi.atlas.config.headers?.Authorization as string
    )?.replace('Bearer ', '');
    if (cookiesIDToken !== configIDToken) {
      marineApi.updateAuthorizationToken(cookiesIDToken);
      const interval = setInterval(() => {
        updateUserInfo(
          jwtDecode(cookies.get(kStorageKey.Cookies.UserInfoToken))
        );
        queryClient.invalidateQueries().then(() => {
          clearInterval(interval);
        });
      }, 1000);
    }
  });

  return <>{children}</>;
};

export default MTLayoutBase;
