import { Layout } from 'antd';

import { IMGCompanyLogo } from '@/assets/img';
import { kColorNeutral } from '@/constants/theme/colorSystem.constants';

import { MTAvatar } from '..';

import './style.scss';

const { Header } = Layout;

const MTHeaderLoader: React.FC = () => {
  return (
    <Header className="mt-header" id="MTHeaderLoader">
      <div className="mt-header__image__wrapper">
        <img
          src={IMGCompanyLogo}
          className="mt-header__image"
          alt="Company Logo"
        />
      </div>

      <MTAvatar
        text=""
        className="mt-header__dropdown__avatar"
        style={{ backgroundColor: kColorNeutral[1] }}
      />
    </Header>
  );
};

export default MTHeaderLoader;
