const Env = {
  APP_VERSION: process.env.REACT_APP_VERSION || '0.0.0',
  ENVIRONMENT: (process.env.REACT_APP_ENVIRONMENT || 'DEV') as
    | 'DEV'
    | 'STG'
    | 'PRD',
  BASE_URL: process.env.REACT_APP_BASE_URL || 'http://localhost:3000',
  API_BARGING_URL:
    process.env.REACT_APP_API_BARGING_URL || 'http://localhost:8000',
  API_ATLAS_URL: process.env.REACT_APP_API_ATLAS_URL || 'http://localhost:8001',
  JWT_SECRET: process.env.REACT_APP_JWT_SECRET || '',
  GOOGLE_MAP_KEY: process.env.REACT_APP_GOOGLE_MAP_KEY || '',
  SENTRY_DNS: process.env.REACT_APP_SENTRY_DNS || '',
  MIX_PANEL_PROJECT_TOKEN: process.env.REACT_APP_MIX_PANEL_PROJECT_TOKEN || '',
} as const;

export default Env;
