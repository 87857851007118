import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import { ICOSorterDown, ICOSorterUp } from '@/assets/ico';
import { ETableOrderBy } from '@/domains/common';
import { paramParsify } from '@/utils/url';

import type {
  MTTableSorterParsedProps,
  MTTableSorterProps,
} from './MTTable.Sorter.types';

import './style.scss';

const Sorter: React.FC<MTTableSorterProps> = ({ sortKey }) => {
  const { search } = useLocation();
  const parsed = paramParsify(search);

  const { order_by, order_field, order_from }: MTTableSorterParsedProps =
    parsed || {};

  return (
    <span className="mt-table-sorter">
      <span className="mt-table-sorter__inner">
        <span
          className={cx(
            'mt-table-sorter__icon mt-table-sorter__icon__up',
            ((order_by === ETableOrderBy.ASC &&
              (order_field || order_from) === sortKey) ||
              parsed?.[sortKey!] === ETableOrderBy.ASC) &&
              'active'
          )}>
          <ICOSorterUp />
        </span>
        <span
          className={cx(
            'mt-table-sorter__icon mt-table-sorter__icon__down',
            ((order_by === ETableOrderBy.DESC &&
              (order_field || order_from) === sortKey) ||
              parsed?.[sortKey!] === ETableOrderBy.DESC) &&
              'active'
          )}>
          <ICOSorterDown />
        </span>
      </span>
    </span>
  );
};

export default Sorter;
