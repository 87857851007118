import ApiEndpoints from '@/domains/api-endpoints';
import { TGetUserInfoResponse } from '@/domains/auth/getUserInfo';
import { TCommonApiResponse } from '@/domains/common';
import { TGetCompanyLogoResponse } from '@/domains/profile';
import { TUploadCompanyLogoParams } from '@/domains/profile/uploadCompanyLogo';

import marineApi from '../services.config';

export default class ProfileApi {
  getCompanyLogo(): Promise<TCommonApiResponse<TGetCompanyLogoResponse>> {
    return marineApi.barging.get(ApiEndpoints.companyLogo);
  }

  uploadCompanyLogo(
    params: TUploadCompanyLogoParams
  ): Promise<TCommonApiResponse> {
    return marineApi.barging.post(ApiEndpoints.companyLogo, params, {
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
      },
    });
  }

  getUserInfo(): Promise<TCommonApiResponse<TGetUserInfoResponse>> {
    return marineApi.atlas.get(ApiEndpoints.userInfo);
  }
}
