import EndpointsBase from '../base';

const BaseUserManagementUser = `${EndpointsBase.BaseUserManagement}/user`;

enum EndpointsUserManagementUser {
  UserManagementUserList = BaseUserManagementUser,
  UserManagementUserAdd = `${BaseUserManagementUser}/add`,
  UserManagementUserEdit = `${BaseUserManagementUser}/:id/edit`,

  UserManagementUserDetail = `${BaseUserManagementUser}/:id`,
  UserManagementUserInformation = `${BaseUserManagementUser}/:id/information`,
  UserManagementUserUpdateLog = `${BaseUserManagementUser}/:id/log`,
}

export default EndpointsUserManagementUser;
