import { useCallback } from 'react';

import { Roles, RouterKey } from '@/domains/common';
import { useAuthStore } from '@/store';

type isUserAllowedProps = {
  routerKey: RouterKey | undefined;
};

const useRole = () => {
  const { userInfo } = useAuthStore();
  const userRole = userInfo?.['custom:roles']?.toString().split(',') as
    | Roles[]
    | undefined;
  const userAccess = JSON.parse(userInfo?.accesses || '{}');

  const isUserAllowed = useCallback(
    ({ routerKey = RouterKey.ALL }: isUserAllowedProps): boolean => {
      let isAllowed = false;

      // IF NO ROLE GROUP RESTRICTION OR USER HAS ALL ROLE GROUP ACCESS
      if (routerKey === RouterKey.ALL || userAccess[RouterKey.ALL]) {
        isAllowed = true;
      }
      // IF USER HAS ROLE GROUP ACCESS
      else if (userAccess && userAccess[routerKey]) {
        isAllowed = true;
      }

      return isAllowed;
    },
    [userAccess]
  );

  return { isUserAllowed, userRole, userAccess };
};

export default useRole;
