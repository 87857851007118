import dayjs from 'dayjs';
import type { CookieSetOptions } from 'universal-cookie';

import Endpoints from '../router';

export const configCookiesOptions: CookieSetOptions = {
  expires: dayjs().add(30, 'days').toDate(),
  path: Endpoints.BaseHome,
};

export const configCookiesSessionOptions: CookieSetOptions = {
  expires: dayjs().add(10, 'minutes').toDate(),
  path: Endpoints.BaseHome,
};
