import cx from 'classnames';

import { MTSpinner } from '@/components';

import { MTLayoutLoaderProps } from './MTLayoutLoader.types';
import './style.scss';

const MTLayoutLoader: React.FC<MTLayoutLoaderProps> = ({
  children,
  className,
}) => {
  return (
    <main className={cx('mt-layout-loader', className)}>
      <MTSpinner size="2rem" />
      {children || <p className="mt-layout-loader__text">Now Loading...</p>}
    </main>
  );
};

export default MTLayoutLoader;
