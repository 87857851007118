import { forwardRef } from 'react';

import { Input } from 'antd';
import type { InputProps, InputRef } from 'antd';
import cx from 'classnames';

import './style.scss';

const MTInputPassword = forwardRef<InputRef, InputProps>(
  ({ className, ...rest }, ref) => (
    <Input.Password
      className={cx('mt-input-password', className)}
      ref={ref}
      {...rest}
    />
  )
);

export default MTInputPassword;
