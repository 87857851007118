export enum Roles {
  Admin = 'admin',
  Operations = 'operations',
  Finance = 'finance',
  Commercial = 'commercial',
  Manager = 'manager',
}

export enum RouterKey {
  ALL = '*',
  DOCUMENT_COLLECTION_VIEW = 'CARGO_OWNER_DASHBOARD:DOCUMENT_COLLECTION:VIEW',
  MONITORING_VIEW = 'CARGO_OWNER_DASHBOARD:MONITORING:VIEW',
  SETTING_UPLOAD_COMPANY_LOGO = 'CARGO_OWNER_DASHBOARD:SETTING:UPLOAD_COMPANY_LOGO',
  SHIPMENT_VIEW = 'CARGO_OWNER_DASHBOARD:SHIPMENT:VIEW',
  SHIPMENT_CREATE = 'CARGO_OWNER_DASHBOARD:SHIPMENT:CREATE',
  USER_ROLE_GROUP_CREATE = 'CARGO_OWNER_DASHBOARD:USER:ROLE_GROUP:CREATE',
  USER_ROLE_GROUP_EDIT = 'CARGO_OWNER_DASHBOARD:USER:ROLE_GROUP:EDIT',
  USER_ROLE_GROUP_VIEW = 'CARGO_OWNER_DASHBOARD:USER:ROLE_GROUP:VIEW',
  USER_USER_CREATE = 'CARGO_OWNER_DASHBOARD:USER:USER:CREATE',
  USER_USER_EDIT = 'CARGO_OWNER_DASHBOARD:USER:USER:EDIT',
  USER_USER_REACTIVATE = 'CARGO_OWNER_DASHBOARD:USER:USER:REACTIVATE',
  USER_USER_REMOVE = 'CARGO_OWNER_DASHBOARD:USER:USER:REMOVE',
  USER_USER_RESEND = 'CARGO_OWNER_DASHBOARD:USER:USER:RESEND',
  USER_USER_VIEW = 'CARGO_OWNER_DASHBOARD:USER:USER:VIEW',
}

export enum ETableOrderBy {
  ASC = 'ASC',
  DESC = 'DESC',
}
