import EndpointsAuth from './auth';
import EndpointsBase from './base';
import EndpointsDocumentCollection from './document-collection';
import EndpointsErrorHandling from './error-handling';
import EndpointsExample from './example';
import EndpointsMonitoring from './monitoring';
import EndpointsShipment from './shipment';
import EndpointsUserManagement from './user-management';

const Endpoints = {
  ...EndpointsExample,
  ...EndpointsBase,
  ...EndpointsAuth,
  ...EndpointsShipment,
  ...EndpointsMonitoring,
  ...EndpointsDocumentCollection,
  ...EndpointsUserManagement,
  ...EndpointsErrorHandling,
} as const;

export default Endpoints;
