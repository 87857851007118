import { AppRouter, TEndpoints } from '@/configs/router/router.types';

import RoutesUserManagementRoleGroup from './role-group.routes';
import RoutesUserManagementUser from './user.routes';

const RoutesUserManagement = {
  ...RoutesUserManagementUser,
  ...RoutesUserManagementRoleGroup,
} as Record<TEndpoints, AppRouter>;

export default RoutesUserManagement;
