import { notification } from 'antd';
import cx from 'classnames';

import { kToastIconDictionary } from '@/constants/component/toast-icon-dictionary/toastIconDictionary.constants';

import type { MTToastProps } from './MTToast.types';
import './style.scss';

const MTToast = ({
  className,
  type = 'success',
  icon,
  content,
  ...props
}: MTToastProps) =>
  notification.open({
    key: 'DEFAULT_KEY',
    className: cx('mt-toast', `mt-toast--type-${type}`, className),
    type: type === 'danger' ? 'error' : type,
    icon:
      icon === null || icon === undefined ? kToastIconDictionary[type] : icon,
    message: content,
    duration: 5,
    ...props,
  });

export default MTToast;
