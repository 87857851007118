import Mixpanel from 'mixpanel-browser';

import Env from '@/configs/environment.config';

const mixpanelToken = Env.MIX_PANEL_PROJECT_TOKEN;

export function initMixpanel() {
  if (!mixpanelToken) return;

  Mixpanel.init(mixpanelToken, {
    debug: true,
    persistence: 'localStorage',
  });
}

export const mixpanel = mixpanelToken
  ? Mixpanel
  : {
      init: () => undefined,
      identify: () => undefined,
      track: () => undefined,
      reset: () => undefined,
      people: {
        set: () => undefined,
      },
    };
