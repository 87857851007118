import AuthApi from './auth';
import DocumentCollectionApi from './document-collection';
import MonitoringApi from './monitoring';
import ProfileApi from './profile';
import ShipmentApi from './shipment';
import UploaderAPI from './uploader';
import UserManagementRoleGroupApi from './user-management/role-group';
import UserManagementUserApi from './user-management/user';

const ApiServices = {
  auth: new AuthApi(),
  monitoring: new MonitoringApi(),
  profile: new ProfileApi(),
  uploader: new UploaderAPI(),
  userManagementRoleGroup: new UserManagementRoleGroupApi(),
  userManagementUser: new UserManagementUserApi(),
  shipment: new ShipmentApi(),
  documentCollection: new DocumentCollectionApi(),
};

export default ApiServices;
