export const kDateFormat = {
  MMM_DD_YYYY_HH_mm: 'MMM DD, YYYY HH:mm', // Mar 10, 2022 15:58
  DD_MMM_YYYY: 'DD MMM YYYY', // 10 Mar 2022
  DD_MMM_YYYY_HH_MM: 'DD MMM YYYY, HH:mm', // 10 Mar 2022
} as const;

export const timezoneAbbr = {
  'Asia/Kabul': 'AFT',
  'Europe/Tirane': 'CEST',
  'Africa/Algiers': 'CET',
  'Pacific/Pago_Pago': 'SST',
  'Europe/Andorra': 'CEST',
  'Africa/Luanda': 'WAT',
  'America/Anguilla': 'AST',
  'Antarctica/Casey': 'CAST',
  'Antarctica/Davis': 'DAVT',
  'Antarctica/DumontDUrville': 'DDUT',
  'Antarctica/Mawson': 'MAWT',
  'Antarctica/McMurdo': 'NZST',
  'Antarctica/Palmer': 'CLST',
  'Antarctica/Rothera': 'ART',
  'Antarctica/Syowa': 'SYOT',
  'Antarctica/Troll': 'CEST',
  'Antarctica/Vostok': 'VOST',
  'America/Antigua': 'AST',
  'America/Argentina/Buenos_Aires': 'ART',
  'America/Argentina/Catamarca': 'ART',
  'America/Argentina/Cordoba': 'ART',
  'America/Argentina/Jujuy': 'ART',
  'America/Argentina/La_Rioja': 'ART',
  'America/Argentina/Mendoza': 'ART',
  'America/Argentina/Rio_Gallegos': 'ART',
  'America/Argentina/Salta': 'ART',
  'America/Argentina/San_Juan': 'ART',
  'America/Argentina/San_Luis': 'ART',
  'America/Argentina/Tucuman': 'ART',
  'America/Argentina/Ushuaia': 'ART',
  'Asia/Yerevan': 'AMT',
  'America/Aruba': 'AST',
  'Antarctica/Macquarie': 'AEST',
  'Australia/Adelaide': 'ACST',
  'Australia/Brisbane': 'AEST',
  'Australia/Broken_Hill': 'ACST',
  'Australia/Darwin': 'ACST',
  'Australia/Eucla': 'ACWST',
  'Australia/Hobart': 'AEST',
  'Australia/Lindeman': 'AEST',
  'Australia/Lord_Howe': 'LHST',
  'Australia/Melbourne': 'AEST',
  'Australia/Perth': 'AWST',
  'Australia/Sydney': 'AEST',
  'Europe/Vienna': 'CEST',
  'Asia/Baku': 'AZT',
  'America/Nassau': 'EDT',
  'Asia/Bahrain': 'AST',
  'Asia/Dhaka': 'BST',
  'America/Barbados': 'AST',
  'Europe/Minsk': 'MSK',
  'Europe/Brussels': 'CEST',
  'America/Belize': 'CST',
  'Africa/Porto-Novo': 'WAT',
  'Atlantic/Bermuda': 'ADT',
  'Asia/Thimphu': 'BTT',
  'America/La_Paz': 'BOT',
  'America/Kralendijk': 'AST',
  'Europe/Sarajevo': 'CEST',
  'Africa/Gaborone': 'CAT',
  'America/Araguaina': 'BRT',
  'America/Bahia': 'BRT',
  'America/Belem': 'BRT',
  'America/Boa_Vista': 'AMT',
  'America/Campo_Grande': 'AMT',
  'America/Cuiaba': 'BRT',
  'America/Eirunepe': 'ACT',
  'America/Fortaleza': 'BRT',
  'America/Maceio': 'BRT',
  'America/Manaus': 'AMT',
  'America/Noronha': 'FNT',
  'America/Porto_Velho': 'AMT',
  'America/Recife': 'BRT',
  'America/Rio_Branco': 'ACT',
  'America/Santarem': 'BRT',
  'America/Sao_Paulo': 'BRT',
  'Indian/Chagos': 'IOT',
  'Asia/Brunei': 'BNT',
  'Europe/Sofia': 'EEST',
  'Africa/Ouagadougou': 'GMT',
  'Africa/Bujumbura': 'CAT',
  'Asia/Phnom_Penh': 'ICT',
  'Africa/Douala': 'WAT',
  'America/Atikokan': 'EST',
  'America/Blanc-Sablon': 'AST',
  'America/Cambridge_Bay': 'MDT',
  'America/Creston': 'MST',
  'America/Dawson': 'MST',
  'America/Dawson_Creek': 'MST',
  'America/Edmonton': 'MDT',
  'America/Fort_Nelson': 'MST',
  'America/Glace_Bay': 'ADT',
  'America/Goose_Bay': 'ADT',
  'America/Halifax': 'ADT',
  'America/Inuvik': 'MDT',
  'America/Iqaluit': 'EDT',
  'America/Moncton': 'ADT',
  'America/Nipigon': 'EDT',
  'America/Pangnirtung': 'EDT',
  'America/Rainy_River': 'CDT',
  'America/Rankin_Inlet': 'CDT',
  'America/Regina': 'CST',
  'America/Resolute': 'CDT',
  'America/St_Johns': 'NDT',
  'America/Swift_Current': 'CST',
  'America/Thunder_Bay': 'EDT',
  'America/Toronto': 'EDT',
  'America/Vancouver': 'PDT',
  'America/Whitehorse': 'MST',
  'America/Winnipeg': 'CDT',
  'America/Yellowknife': 'MDT',
  'Atlantic/Cape_Verde': 'CVT',
  'America/Cayman': 'EST',
  'Africa/Bangui': 'WAT',
  'Africa/Ndjamena': 'WAT',
  'America/Punta_Arenas': 'CLST',
  'America/Santiago': 'CLT',
  'Pacific/Easter': 'EAST',
  'Asia/Shanghai': 'CST',
  'Asia/Urumqi': 'URUT',
  'Indian/Christmas': 'CXT',
  'Indian/Cocos': 'CCT',
  'America/Bogota': 'COT',
  'Indian/Comoro': 'EAT',
  'Africa/Brazzaville': 'WAT',
  'Africa/Kinshasa': 'WAT',
  'Africa/Lubumbashi': 'CAT',
  'Pacific/Rarotonga': 'CKT',
  'America/Costa_Rica': 'CST',
  'Europe/Zagreb': 'CEST',
  'America/Havana': 'CDT',
  'America/Curacao': 'AST',
  'Asia/Famagusta': 'EEST',
  'Asia/Nicosia': 'EEST',
  'Europe/Prague': 'CEST',
  'Africa/Abidjan': 'GMT',
  'Europe/Copenhagen': 'CEST',
  'Africa/Djibouti': 'EAT',
  'America/Dominica': 'AST',
  'America/Santo_Domingo': 'AST',
  'America/Guayaquil': 'ECT',
  'Pacific/Galapagos': 'GALT',
  'Africa/Cairo': 'EET',
  'America/El_Salvador': 'CST',
  'Africa/Malabo': 'WAT',
  'Africa/Asmara': 'EAT',
  'Europe/Tallinn': 'EEST',
  'Africa/Addis_Ababa': 'EAT',
  'Atlantic/Stanley': 'FKT',
  'Atlantic/Faroe': 'WEST',
  'Pacific/Fiji': 'FJT',
  'Europe/Helsinki': 'EEST',
  'Europe/Paris': 'CEST',
  'America/Cayenne': 'GFT',
  'Pacific/Gambier': 'GAMT',
  'Pacific/Marquesas': 'MART',
  'Pacific/Tahiti': 'TAHT',
  'Indian/Kerguelen': 'TFT',
  'Africa/Libreville': 'WAT',
  'Africa/Banjul': 'GMT',
  'Asia/Tbilisi': 'GET',
  'Europe/Berlin': 'CEST',
  'Europe/Busingen': 'CEST',
  'Africa/Accra': 'GMT',
  'Europe/Gibraltar': 'CEST',
  'Europe/Athens': 'EEST',
  'America/Danmarkshavn': 'GMT',
  'America/Nuuk': 'WGST',
  'America/Scoresbysund': 'EGST',
  'America/Thule': 'ADT',
  'America/Grenada': 'AST',
  'America/Guadeloupe': 'AST',
  'Pacific/Guam': 'ChST',
  'America/Guatemala': 'CST',
  'Europe/Guernsey': 'BST',
  'Africa/Conakry': 'GMT',
  'Africa/Bissau': 'GMT',
  'America/Guyana': 'GYT',
  'America/Port-au-Princ': 'EDT',
  'Europe/Vatican': 'CEST',
  'America/Tegucigalpa': 'CST',
  'Asia/Hong_Kong': 'HKT',
  'Europe/Budapest': 'CEST',
  'Atlantic/Reykjavik': 'GMT',
  'Asia/Kolkata': 'IST',
  'Asia/Jakarta': 'WIB',
  'Asia/Jayapura': 'WIT',
  'Asia/Makassar': 'WITA',
  'Asia/Pontianak': 'WIB',
  'Asia/Tehran': 'IRST',
  'Asia/Baghdad': 'AST',
  'Europe/Dublin': 'IST',
  'Europe/Isle_of_Man': 'BST',
  'Asia/Jerusalem': 'IDT',
  'Europe/Rome': 'CEST',
  'America/Jamaica': 'EST',
  'Asia/Tokyo': 'JST',
  'Europe/Jersey': 'BST',
  'Asia/Amman': 'EEST',
  'Asia/Almaty': 'ALMT',
  'Asia/Aqtau': 'MSD+1',
  'Asia/Aqtobe': 'AQTT',
  'Asia/Atyrau': 'MSD+1',
  'Asia/Oral': 'ORAST',
  'Asia/Qostanay': 'QYZST',
  'Asia/Qyzylorda': 'MSK+2',
  'Africa/Nairobi': 'EAT',
  'Pacific/Kanton': 'EST',
  'Pacific/Kiritimati': 'LINT',
  'Pacific/Tarawa': 'GILT',
  'Asia/Pyongyang': 'KST',
  'Asia/Seoul': 'KST',
  'Asia/Kuwait': 'AST',
  'Asia/Bishkek': 'KGST',
  'Asia/Vientiane': 'ICT',
  'Europe/Riga': 'EEST',
  'Asia/Beirut': 'EEST',
  'Africa/Maseru': 'SAST',
  'Africa/Monrovia': 'GMT',
  'Africa/Tripoli': 'EET',
  'Europe/Vaduz': 'CEST',
  'Europe/Vilnius': 'EEST',
  'Europe/Luxembourg': 'CEST',
  'Asia/Macau': 'CST',
  'Europe/Skopje': 'CEST',
  'Indian/Antananarivo': 'EAT',
  'Africa/Blantyre': 'CAT',
  'Asia/Kuala_Lumpur': 'MYT',
  'Asia/Kuching': 'MYT',
  'Indian/Maldives': 'MVT',
  'Africa/Bamako': 'GMT',
  'Europe/Malta': 'CEST',
  'Pacific/Kwajalein': '+12',
  'Pacific/Majuro': '+12',
  'America/Martinique': 'AST',
  'Africa/Nouakchott': 'GMT',
  'Indian/Mauritius': 'MUT',
  'Indian/Mayotte': 'EAT',
  'America/Bahia_Banderas': 'CDT',
  'America/Cancun': 'EST',
  'America/Chihuahua': 'MDT',
  'America/Hermosillo': 'MST',
  'America/Matamoros': 'CDT',
  'America/Mazatlan': 'MDT',
  'America/Merida': 'CDT',
  'America/Mexico_City': 'CDT',
  'America/Monterrey': 'CDT',
  'America/Ojinaga': 'MDT',
  'America/Tijuana': 'PDT',
  'Pacific/Chuuk': 'CHUT',
  'Pacific/Kosrae': 'KOST',
  'Pacific/Pohnpei': 'PONT',
  'Europe/Chisinau': 'EEST',
  'Europe/Monaco': 'CEST',
  'Asia/Choibalsan': 'CHOT',
  'Asia/Hovd': 'HOVT',
  'Asia/Ulaanbaatar': 'ULAT',
  'Europe/Podgorica': 'CEST',
  'America/Montserrat': 'AST',
  'Africa/Casablanca': 'WEST',
  'Africa/Maputo': 'CAT',
  'Asia/Yangon': 'MMT',
  'Africa/Windhoek': 'CAT',
  'Pacific/Nauru': 'NRT',
  'Asia/Kathmandu': 'NPT',
  'Europe/Amsterdam': 'CEST',
  'Pacific/Noumea': 'NCT',
  'Pacific/Auckland': 'NZST',
  'Pacific/Chatham': 'CHAST',
  'America/Managua': 'CST',
  'Africa/Niamey': 'WAT',
  'Africa/Lagos': 'WAT',
  'Pacific/Niue': 'NUT',
  'Pacific/Norfolk': 'NFT',
  'Pacific/Saipan': 'ChST',
  'Europe/Oslo': 'CEST',
  'Asia/Muscat': 'GST',
  'Asia/Karachi': 'PKT',
  'Pacific/Palau': '+09',
  'Asia/Gaza': 'EEST',
  'Asia/Hebron': 'EEST',
  'America/Panama': 'EST',
  'Pacific/Bougainville': 'BST',
  'Pacific/Port_Moresby': 'PGT',
  'America/Asuncion': 'PYT',
  'America/Lima': 'PET',
  'Asia/Manila': 'PST',
  'Pacific/Pitcairn': 'PST',
  'Europe/Warsaw': 'CEST',
  'Atlantic/Azores': 'AZOST',
  'Atlantic/Madeira': 'WEST',
  'Europe/Lisbon': 'WEST',
  'America/Puerto_Rico': 'AST',
  'Asia/Qatar': 'AST',
  'Europe/Bucharest': 'EEST',
  'Asia/Anadyr': 'ANAT',
  'Asia/Barnaul': 'MSK+4',
  'Asia/Chita': 'YAKT',
  'Asia/Irkutsk': 'IRKT',
  'Asia/Kamchatka': 'PETT',
  'Asia/Khandyga': 'YAKT',
  'Asia/Krasnoyarsk': 'KRAT',
  'Asia/Magadan': 'MAGT',
  'Asia/Novokuznetsk': 'KRAT',
  'Asia/Novosibirsk': 'NOVT',
  'Asia/Omsk': 'OMST',
  'Asia/Sakhalin': 'SAKT',
  'Asia/Srednekolymsk': 'SRET',
  'Asia/Tomsk': 'MSD+3',
  'Asia/Ust-Nera': 'VLAT',
  'Asia/Vladivostok': 'VLAT',
  'Asia/Yakutsk': 'YAKT',
  'Asia/Yekaterinburg': 'YEKT',
  'Europe/Astrakhan': 'MSK+1',
  'Europe/Kaliningrad': 'EET',
  'Europe/Kirov': 'MSK',
  'Europe/Moscow': 'MSK',
  'Europe/Samara': 'SAMT',
  'Europe/Saratov': 'MSD',
  'Europe/Ulyanovsk': 'MSK+1',
  'Europe/Volgograd': 'VOLT',
  'Africa/Kigali': 'CAT',
  'Indian/Reunion': 'RET',
  'America/St_Barthelemy': 'AST',
  'Atlantic/St_Helena': 'GMT',
  'America/St_Kitts': 'AST',
  'America/St_Lucia': 'AST',
  'America/Marigot': 'AST',
  'America/Miquelon': 'PMST',
  'America/St_Vincent': 'AST',
  'Pacific/Apia': 'WST',
  'Europe/San_Marino': 'CEST',
  'Africa/Sao_Tome': 'GMT',
  'Asia/Riyadh': 'AST',
  'Africa/Dakar': 'GMT',
  'Europe/Belgrade': 'CEST',
  'Indian/Mahe': 'SCT',
  'Africa/Freetown': 'GMT',
  'Asia/Singapore': 'SST',
  'America/Lower_Prince': 'AST',
  'Europe/Bratislava': 'CEST',
  'Europe/Ljubljana': 'CEST',
  'Pacific/Guadalcanal': 'SBT',
  'Africa/Mogadishu': 'EAT',
  'Africa/Johannesburg': 'SAST',
  'Atlantic/South_Georgia': 'GST',
  'Africa/Juba': 'CAT',
  'Africa/Ceuta': 'CEST',
  'Atlantic/Canary': 'WEST',
  'Europe/Madrid': 'CEST',
  'Asia/Colombo': 'IST',
  'Africa/Khartoum': 'CAT',
  'America/Paramaribo': 'SRT',
  'Arctic/Longyearbyen': 'CEST',
  'Africa/Mbabane': 'SAST',
  'Europe/Stockholm': 'CEST',
  'Europe/Zurich': 'CEST',
  'Asia/Damascus': 'EEST',
  'Asia/Taipei': 'CST',
  'Asia/Dushanbe': 'TJT',
  'Africa/Dar_es_Salaam': 'EAT',
  'Asia/Bangkok': 'ICT',
  'Asia/Dili': 'TLT',
  'Africa/Lome': 'GMT',
  'Pacific/Fakaofo': 'TKT',
  'Pacific/Tongatapu': 'TOT',
  'America/Port_of_Spain': 'AST',
  'Africa/Tunis': 'CET',
  'Europe/Istanbul': 'TRT',
  'Asia/Ashgabat': 'TMT',
  'America/Grand_Turk': 'EDT',
  'Pacific/Funafuti': 'TVT',
  'Africa/Kampala': 'EAT',
  'Europe/Kyiv': 'EEST',
  'Europe/Simferopol': 'MSK',
  'Europe/Uzhgorod': 'EEST',
  'Europe/Zaporozhye': 'EEST',
  'Asia/Dubai': 'GST',
  'Europe/London': 'BST',
  'America/Adak': 'HDT',
  'America/Anchorage': 'AKDT',
  'America/Boise': 'MDT',
  'America/Chicago': 'CDT',
  'America/Denver': 'MDT',
  'America/Detroit': 'EDT',
  'America/Indiana/Indianapolis': 'EDT',
  'America/Indiana/Knox': 'CDT',
  'America/Indiana/Marengo': 'EDT',
  'America/Indiana/Petersburg': 'EDT',
  'America/Indiana/Tell_City': 'CDT',
  'America/Indiana/Vevay': 'EDT',
  'America/Indiana/Vincennes': 'EDT',
  'America/Indiana/Winamac': 'EDT',
  'America/Juneau': 'AKDT',
  'America/Kentucky/Louisville': 'EDT',
  'America/Kentucky/Monticello': 'EDT',
  'America/Los_Angeles': 'PDT',
  'America/Menominee': 'CDT',
  'America/Metlakatla': 'AKDT',
  'America/New_York': 'EDT',
  'America/Nome': 'AKDT',
  'America/North_Dakota/Beulah': 'CDT',
  'America/North_Dakota/Center': 'CDT',
  'America/North_Dakota/New_Salem': 'CDT',
  'America/Phoenix': 'MST',
  'America/Sitka': 'AKDT',
  'America/Yakutat': 'AKDT',
  'Pacific/Honolulu': 'HST',
  'Pacific/Midway': 'SST',
  'Pacific/Wake': 'WAKT',
  'America/Montevideo': 'UYT',
  'Asia/Samarkand': 'UZT',
  'Asia/Tashkent': 'UZT',
  'Pacific/Efate': 'VUT',
  'America/Caracas': 'VET',
  'Asia/Ho_Chi_Minh': 'IDT',
  'America/Tortola': 'AST',
  'America/St_Thomas': 'AST',
  'Pacific/Wallis': 'WFT',
  'Africa/El_Aaiun': 'WEST',
  'Asia/Ade': 'AST',
  'Africa/Lusaka': 'CAT',
  'Africa/Harare': 'CAT',
  'Europe/Mariehamn': 'EEST',
} as const;
