// Color System
// -----------------------------------------------------------
// Color palettes defined for use throughout the design system.

// CAUTION! If you want to change the value, or want to
// add more colors, please sync up in `./scss/variables/
// _colorSystem.scss` too to prevent confusion.

/**
 * ![#1069B8](https://placehold.co/2x2/1069B8/1069B8.webp)
 * ![#E7F0F8](https://placehold.co/2x2/E7F0F8/E7F0F8.webp)
 * ![#AFCDE7](https://placehold.co/2x2/AFCDE7/AFCDE7.webp)
 * ![#609BD0](https://placehold.co/2x2/609BD0/609BD0.webp)
 * ![#0D5799](https://placehold.co/2x2/0D5799/0D5799.webp)
 * ![#0B467B](https://placehold.co/2x2/0B467B/0B467B.webp)
 * ![#08355C](https://placehold.co/2x2/08355C/08355C.webp)
 */
export const kColorPrimary = {
  /** ![#1069B8](https://placehold.co/2x2/1069B8/1069B8.webp) */
  main: '#1069B8',
  /** ![#E7F0F8](https://placehold.co/2x2/E7F0F8/E7F0F8.webp) */
  '0': '#E7F0F8',
  /** ![#AFCDE7](https://placehold.co/2x2/AFCDE7/AFCDE7.webp) */
  '1': '#AFCDE7',
  /** ![#609BD0](https://placehold.co/2x2/609BD0/609BD0.webp) */
  '2': '#609BD0',
  /** ![#0D5799](https://placehold.co/2x2/0D5799/0D5799.webp) */
  '3': '#0D5799',
  /** ![#0B467B](https://placehold.co/2x2/0B467B/0B467B.webp) */
  '4': '#0B467B',
  /** ![#08355C](https://placehold.co/2x2/08355C/08355C.webp) */
  '5': '#08355C',
} as const;

/**
 * ![#27527D](https://placehold.co/2x2/27527D/27527D.webp)
 * ![#E9EEF2](https://placehold.co/2x2/E9EEF2/E9EEF2.webp)
 * ![#B7C4D4](https://placehold.co/2x2/B7C4D4/B7C4D4.webp)
 * ![#93A8BE](https://placehold.co/2x2/93A8BE/93A8BE.webp)
 * ![#6F8CA8](https://placehold.co/2x2/6F8CA8/6F8CA8.webp)
 * ![#4B6F93](https://placehold.co/2x2/4B6F93/4B6F93.webp)
 * ![#1A3753](https://placehold.co/2x2/1A3753/1A3753.webp)
 */
export const kColorSecondary = {
  /** ![#27527D](https://placehold.co/2x2/27527D/27527D.webp) */
  main: '#27527D',
  /** ![#E9EEF2](https://placehold.co/2x2/E9EEF2/E9EEF2.webp) */
  '0': '#E9EEF2',
  /** ![#B7C4D4](https://placehold.co/2x2/B7C4D4/B7C4D4.webp) */
  '1': '#B7C4D4',
  /** ![#93A8BE](https://placehold.co/2x2/93A8BE/93A8BE.webp) */
  '2': '#93A8BE',
  /** ![#6F8CA8](https://placehold.co/2x2/6F8CA8/6F8CA8.webp) */
  '3': '#6F8CA8',
  /** ![#4B6F93](https://placehold.co/2x2/4B6F93/4B6F93.webp) */
  '4': '#4B6F93',
  /** ![#1A3753](https://placehold.co/2x2/1A3753/1A3753.webp) */
  '5': '#1A3753',
} as const;

/**
 * ![#31353B](https://placehold.co/2x2/31353B/31353B.webp)
 * ![#FFFFFF](https://placehold.co/2x2/FFFFFF/FFFFFF.webp)
 * ![#D6D7D8](https://placehold.co/2x2/D6D7D8/D6D7D8.webp)
 * ![#ADAEB1](https://placehold.co/2x2/ADAEB1/ADAEB1.webp)
 * ![#838689](https://placehold.co/2x2/838689/838689.webp)
 * ![#272a2f](https://placehold.co/2x2/272a2f/272a2f.webp)
 * ![#141518](https://placehold.co/2x2/141518/141518.webp)
 */
export const kColorNeutral = {
  /** ![#31353B](https://placehold.co/2x2/31353B/31353B.webp) */
  main: '#31353B',
  /** ![#FFFFFF](https://placehold.co/2x2/FFFFFF/FFFFFF.webp) */
  '0': '#FFFFFF',
  /** ![#D6D7D8](https://placehold.co/2x2/D6D7D8/D6D7D8.webp) */
  '1': '#D6D7D8',
  /** ![#ADAEB1](https://placehold.co/2x2/ADAEB1/ADAEB1.webp) */
  '2': '#ADAEB1',
  /** ![#838689](https://placehold.co/2x2/838689/838689.webp) */
  '3': '#838689',
  /** ![#272a2f](https://placehold.co/2x2/272a2f/272a2f.webp) */
  '4': '#272a2f',
  /** ![#141518](https://placehold.co/2x2/141518/141518.webp) */
  '5': '#141518',
} as const;

/**
 * ![#57AF31](https://placehold.co/2x2/57AF31/57AF31.webp)
 * ![#F3FBEF](https://placehold.co/2x2/F3FBEF/F3FBEF.webp)
 * ![#CDE7C1](https://placehold.co/2x2/CDE7C1/CDE7C1.webp)
 * ![#9ACF83](https://placehold.co/2x2/9ACF83/9ACF83.webp)
 * ![#79BF5A](https://placehold.co/2x2/79BF5A/79BF5A.webp)
 * ![#468C27](https://placehold.co/2x2/468C27/468C27.webp)
 * ![#34691D](https://placehold.co/2x2/34691D/34691D.webp)
 */
export const kColorSuccess = {
  /** ![#57AF31](https://placehold.co/2x2/57AF31/57AF31.webp) */
  main: '#57AF31',
  /** ![#F3FBEF](https://placehold.co/2x2/F3FBEF/F3FBEF.webp) */
  '0': '#F3FBEF',
  /** ![#CDE7C1](https://placehold.co/2x2/CDE7C1/CDE7C1.webp) */
  '1': '#CDE7C1',
  /** ![#9ACF83](https://placehold.co/2x2/9ACF83/9ACF83.webp) */
  '2': '#9ACF83',
  /** ![#79BF5A](https://placehold.co/2x2/79BF5A/79BF5A.webp) */
  '3': '#79BF5A',
  /** ![#468C27](https://placehold.co/2x2/468C27/468C27.webp) */
  '4': '#468C27',
  /** ![#34691D](https://placehold.co/2x2/34691D/34691D.webp) */
  '5': '#34691D',
} as const;

/**
 * ![#EE5046](https://placehold.co/2x2/EE5046/EE5046.webp)
 * ![#FFF3F2](https://placehold.co/2x2/FFF3F2/FFF3F2.webp)
 * ![#FCDCDA](https://placehold.co/2x2/FCDCDA/FCDCDA.webp)
 * ![#F8B9B5](https://placehold.co/2x2/F8B9B5/F8B9B5.webp)
 * ![#F1736B](https://placehold.co/2x2/F1736B/F1736B.webp)
 * ![#CD4239](https://placehold.co/2x2/CD4239/CD4239.webp)
 * ![#AC342D](https://placehold.co/2x2/AC342D/AC342D.webp)
 */
export const kColorDanger = {
  /** ![#EE5046](https://placehold.co/2x2/EE5046/EE5046.webp) */
  main: '#EE5046',
  /** ![#FFF3F2](https://placehold.co/2x2/FFF3F2/FFF3F2.webp) */
  '0': '#FFF3F2',
  /** ![#FCDCDA](https://placehold.co/2x2/FCDCDA/FCDCDA.webp) */
  '1': '#FCDCDA',
  /** ![#F8B9B5](https://placehold.co/2x2/F8B9B5/F8B9B5.webp) */
  '2': '#F8B9B5',
  /** ![#F1736B](https://placehold.co/2x2/F1736B/F1736B.webp) */
  '3': '#F1736B',
  /** ![#CD4239](https://placehold.co/2x2/CD4239/CD4239.webp) */
  '4': '#CD4239',
  /** ![#AC342D](https://placehold.co/2x2/AC342D/AC342D.webp) */
  '5': '#AC342D',
} as const;

/**
 * ![#F8C33D](https://placehold.co/2x2/F8C33D/F8C33D.webp)
 * ![#FFFAED](https://placehold.co/2x2/FFFAED/FFFAED.webp)
 * ![#FDEDC5](https://placehold.co/2x2/FDEDC5/FDEDC5.webp)
 * ![#FCE19E](https://placehold.co/2x2/FCE19E/FCE19E.webp)
 * ![#F9CF64](https://placehold.co/2x2/F9CF64/F9CF64.webp)
 * ![#D6A731](https://placehold.co/2x2/D6A731/D6A731.webp)
 * ![#B48B26](https://placehold.co/2x2/B48B26/B48B26.webp)
 */
export const kColorWarning = {
  /** ![#F8C33D](https://placehold.co/2x2/F8C33D/F8C33D.webp) */
  main: '#F8C33D',
  /** ![#FFFAED](https://placehold.co/2x2/FFFAED/FFFAED.webp) */
  '0': '#FFFAED',
  /** ![#FDEDC5](https://placehold.co/2x2/FDEDC5/FDEDC5.webp) */
  '1': '#FDEDC5',
  /** ![#FCE19E](https://placehold.co/2x2/FCE19E/FCE19E.webp) */
  '2': '#FCE19E',
  /** ![#F9CF64](https://placehold.co/2x2/F9CF64/F9CF64.webp) */
  '3': '#F9CF64',
  /** ![#D6A731](https://placehold.co/2x2/D6A731/D6A731.webp) */
  '4': '#D6A731',
  /** ![#B48B26](https://placehold.co/2x2/B48B26/B48B26.webp) */
  '5': '#B48B26',
} as const;

/**
 * ![#034F93](https://placehold.co/2x2/034F93/034F93.webp)
 * ![#E3EDF4](https://placehold.co/2x2/E3EDF4/E3EDF4.webp)
 * ![#CADBE9](https://placehold.co/2x2/CADBE9/CADBE9.webp)
 * ![#98B8D4](https://placehold.co/2x2/98B8D4/98B8D4.webp)
 * ![#3572A9](https://placehold.co/2x2/3572A9/3572A9.webp)
 * ![#022F58](https://placehold.co/2x2/022F58/022F58.webp)
 * ![#01182C](https://placehold.co/2x2/01182C/01182C.webp)
 */
export const kColorAtlas = {
  /** ![#034F93](https://placehold.co/2x2/034F93/034F93.webp) */
  main: '#034F93',
  /** ![#E3EDF4](https://placehold.co/2x2/E3EDF4/E3EDF4.webp) */
  '0': '#E3EDF4',
  /** ![#CADBE9](https://placehold.co/2x2/CADBE9/CADBE9.webp) */
  '1': '#CADBE9',
  /** ![#98B8D4](https://placehold.co/2x2/98B8D4/98B8D4.webp) */
  '2': '#98B8D4',
  /** ![#3572A9](https://placehold.co/2x2/3572A9/3572A9.webp) */
  '3': '#3572A9',
  /** ![#022F58](https://placehold.co/2x2/022F58/022F58.webp) */
  '4': '#022F58',
  /** ![#01182C](https://placehold.co/2x2/01182C/01182C.webp) */
  '5': '#01182C',
} as const;

/**
 * ![#1069B8](https://placehold.co/2x2/1069B8/1069B8.webp)
 * ![#D2AA44](https://placehold.co/2x2/D2AA44/D2AA44.webp)
 */
export const kColorBranding = {
  /** ![#1069B8](https://placehold.co/2x2/1069B8/1069B8.webp) */
  blue: kColorPrimary.main,
  /** ![#D2AA44](https://placehold.co/2x2/D2AA44/D2AA44.webp) */
  gold: '#D2AA44',
} as const;
