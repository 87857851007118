import React, { useMemo } from 'react';

import { Avatar } from 'antd';

import {
  kColorNeutral,
  kColorPrimary,
} from '@/constants/theme/colorSystem.constants';

import { MTAvatarProps } from './MTAvatar.types';

const MAX_CHAR = 1;

const MTAvatar = React.forwardRef<HTMLElement, MTAvatarProps>(
  (
    { text, isTruncate = true, size = 36, style, randomColor, ...props },
    ref
  ) => {
    const textInitials = useMemo(
      () =>
        text
          .split(' ')
          .slice(0, MAX_CHAR)
          .map((t) => t.charAt(0))
          .join('')
          .toUpperCase(),
      [text]
    );

    const customHue = useMemo(() => {
      // get hue based on interval of 40 since it significantly change the color.
      const getHue = (x: number) => Math.ceil(x / 40) * 40;
      const minSaturation = 50; // 50-60% for normal color
      const maxSaturation = 60; // 50-60% for normal color
      const minLightness = 40; // 40-50% for normal color
      const maxLightness = 50; // 40-50% for normal color

      // get random code from The Collatz Conjecture Problem mod by 360 to fit hue range
      const normalizedCode =
        text
          .split('')
          .reduce((total, curr) => (total += curr.charCodeAt(0) * 3 + 1), 0) %
        360;

      const hue = getHue(normalizedCode);
      let saturation = minSaturation + (normalizedCode % 10);
      saturation = saturation > maxSaturation ? maxSaturation : saturation;
      let lightness = minLightness + (normalizedCode % 10);
      lightness = lightness > maxLightness ? maxLightness : lightness;

      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    }, [text]);

    const displayText = useMemo(
      () => (isTruncate ? textInitials : text),
      [isTruncate, text, textInitials]
    );

    return (
      <Avatar
        ref={ref}
        size={size || 36}
        style={{
          background: randomColor ? customHue : kColorPrimary.main,
          color: kColorNeutral[0],
          verticalAlign: 'middle',
          ...style,
        }}
        {...props}>
        {displayText}
      </Avatar>
    );
  }
);

MTAvatar.displayName = 'MTAvatar';

export default MTAvatar;
