import React, { forwardRef, useCallback, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import {
  GoogleMap,
  OverlayViewF,
  OVERLAY_MOUSE_TARGET,
  useJsApiLoader,
  MarkerF,
} from '@react-google-maps/api';
import { Easing, Tween, update } from '@tweenjs/tween.js';
import cx from 'classnames';

import Env from '@/configs/environment.config';

import {
  MTMapBoxProps,
  TGoogleMap,
  TInitialViewStateBounds,
  TInitialViewStateLatLng,
} from './MTMapBox.types';

import './style.scss';

const centerOverlayView = (width: number, height: number) => {
  return {
    x: -(width / 2),
    y: -(height / 2),
  };
};

export const Popup: React.FC<
  React.PropsWithChildren<{
    offset: string | number;
    longitude: number;
    latitude: number;
    onClose: () => void;
  }>
> = ({ longitude: lng, latitude: lat, children, onClose, offset }) => {
  const [overlayPane] = useState(OVERLAY_MOUSE_TARGET);

  if (!children) return <MarkerF position={{ lat, lng }} />;

  return (
    <OverlayViewF
      position={{ lat, lng }}
      mapPaneName={overlayPane}
      getPixelPositionOffset={centerOverlayView}>
      <div style={{ position: 'relative' }}>
        <div
          className={cx('mt-map__popup', 'mt-map__popup--bottom')}
          style={{ bottom: offset }}>
          <CloseOutlined onClick={onClose} className="mt-map__popup__close" />
          {children}
        </div>
      </div>
    </OverlayViewF>
  );
};

export const Marker: React.FC<
  React.PropsWithChildren<{
    longitude: number;
    latitude: number;
    rotation?: number;
    onClick?: () => void;
  }>
> = ({ longitude: lng, latitude: lat, onClick, rotation, children }) => {
  const [overlayPane] = useState(OVERLAY_MOUSE_TARGET);

  if (!children) return <MarkerF position={{ lat, lng }} />;

  return (
    <OverlayViewF
      position={{ lat, lng }}
      mapPaneName={overlayPane}
      getPixelPositionOffset={centerOverlayView}>
      <div
        onClick={onClick}
        style={{ rotate: `${rotation} deg`, position: 'relative', zIndex: 1 }}>
        {children}
      </div>
    </OverlayViewF>
  );
};

const MTMapBox = forwardRef<GoogleMap | null, MTMapBoxProps>(
  (
    {
      id = 'google-map',
      children,
      mapRef,
      minZoom,
      maxZoom,
      initialViewState,
      onLoad,
      zoom = 5,
      center = { lat: -2.4, lng: 120 },
      tilt,
      heading,
      ...props
    },
    ref
  ) => {
    const { isLoaded } = useJsApiLoader({
      id,
      googleMapsApiKey: Env.GOOGLE_MAP_KEY,
    });

    const handleLoad = useCallback(
      (loadedMap: google.maps.Map) => {
        if (initialViewState) {
          let bounds;

          if ((initialViewState as TInitialViewStateBounds).bounds) {
            const initVal = initialViewState as TInitialViewStateBounds;
            bounds = new google.maps.LatLngBounds({
              west: initVal.bounds[0][0],
              south: initVal.bounds[0][1],
              east: initVal.bounds[1][0],
              north: initVal.bounds[1][1],
            });
          } else {
            const initVal = initialViewState as TInitialViewStateLatLng;
            bounds = new google.maps.LatLngBounds({
              lat: initVal.latitude,
              lng: initVal.longitude,
            });
          }
          loadedMap.fitBounds(bounds);
        }
        if (mapRef) {
          mapRef['current'] = loadedMap as TGoogleMap;
          mapRef.current['flyTo'] = (flyProps: {
            center: [number, number];
            zoom?: number;
            tilt?: number;
            heading?: number;
          }) => {
            const cameraOptions: google.maps.CameraOptions = {
              tilt,
              heading,
              zoom,
              center: {
                lat: loadedMap.getCenter()?.lat() ?? (center.lat as number),
                lng: loadedMap.getCenter()?.lng() ?? (center.lng as number),
              },
            };
            new Tween(cameraOptions)
              .to(
                {
                  tilt: flyProps.tilt,
                  heading: flyProps.heading,
                  zoom: flyProps.zoom,
                  center: { lat: flyProps.center[1], lng: flyProps.center[0] },
                },
                1500
              )
              .easing(Easing.Quadratic.InOut)
              .onUpdate(() => {
                loadedMap.moveCamera(cameraOptions);
              })
              .start(); // Start the tween immediately.

            function animate(time: number) {
              requestAnimationFrame(animate);
              update(time);
            }

            requestAnimationFrame(animate);
          };
        }
        onLoad?.(loadedMap);
      },
      [center, heading, initialViewState, mapRef, onLoad, tilt, zoom]
    );

    if (!isLoaded) return null;

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '5px',
          overflow: 'hidden',
        }}>
        <GoogleMap
          ref={ref}
          id={id}
          zoom={zoom}
          center={center}
          mapContainerStyle={{
            width: '100%',
            height: '100%',
          }}
          options={{
            mapTypeControl: false,
            zoomControl: false,
            streetViewControl: false,
            minZoom: minZoom || 5,
            maxZoom: maxZoom || 11,
          }}
          onLoad={handleLoad}
          {...props}>
          {children}
        </GoogleMap>
      </div>
    );
  }
);

export default MTMapBox;
