import { useState } from 'react';

import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type {
  CheckboxOptionType,
  CheckboxValueType,
} from 'antd/es/checkbox/Group';
import cx from 'classnames';

import type { MTCheckboxMultiProps } from '.';

import './style.scss';

const MTCheckboxMulti: React.FC<MTCheckboxMultiProps> = ({
  className,
  defaultValue,
  options = [],
  showSelectAll = true,
  style,
  onChange,
  ...rest
}) => {
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(
    defaultValue || []
  );
  const [indeterminate, setIndeterminate] = useState<boolean>(true);

  const handleChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);
    onChange?.(list);
  };

  const handleCheckAllChange = ({ target }: CheckboxChangeEvent) => {
    setCheckedList(
      target.checked
        ? options.map((el) => {
            return ((el as CheckboxOptionType)?.value || el) as string;
          })
        : []
    );
    setIndeterminate(false);
    setCheckAll(target.checked);
    onChange?.(
      target.checked
        ? options.map((el) => {
            return ((el as CheckboxOptionType)?.value || el) as string;
          })
        : []
    );
  };

  return (
    <>
      {showSelectAll && (
        <Checkbox
          indeterminate={indeterminate}
          onChange={handleCheckAllChange}
          checked={checkAll}>
          Select all
        </Checkbox>
      )}
      <Checkbox.Group
        options={options}
        value={checkedList}
        onChange={handleChange}
        className={cx('mt-checkbox-multi', className)}
        style={style}
        {...rest}
      />
    </>
  );
};

export default MTCheckboxMulti;
