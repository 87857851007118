import { forwardRef, useMemo } from 'react';

import { InputProps, InputRef } from 'antd';
import cx from 'classnames';

import MTInput from '../basic/MTInput.component';

import { useMTInputOTP } from './MTInputOTP.hooks';
import type { MTInputOTPProps } from './MTInputOTP.types';

import './style.scss';

const MTInputOTPSingle = forwardRef<InputRef, InputProps>(
  ({ className, ...rest }, ref) => {
    return (
      <MTInput
        className={cx('mt-input-otp__field', className)}
        maxLength={1}
        ref={ref}
        {...rest}
      />
    );
  }
);

const MTInputOTP = forwardRef<HTMLDivElement, MTInputOTPProps>(
  (
    {
      className,
      disabled,
      inputClassName,
      length = 6,
      placeholder,
      onChange,
      value,
      id,
      ...rest
    },
    ref
  ) => {
    const {
      handleChange,
      handleFocus,
      handleKeyDown,
      handleKeyPress,
      handlePaste,
      otpValue,
    } = useMTInputOTP({ length, onChange });

    const makeLength = useMemo(() => {
      if (length < 2) return 2;
      if (length > 16) return 16;
      return length;
    }, [length]);

    return (
      <div className={cx('mt-input-otp', className)} ref={ref} id={id}>
        {Array(makeLength)
          .fill(null)
          .map((_, idx) => (
            <MTInputOTPSingle
              key={idx}
              onFocus={handleFocus}
              // onChange won't triggered when the field
              // filled with same value, therefore using
              // onInput.
              onPaste={handlePaste}
              onInput={handleChange}
              onKeyDown={handleKeyDown}
              onKeyPress={handleKeyPress}
              className={inputClassName}
              disabled={disabled}
              placeholder={
                placeholder?.length === 1 ? placeholder : placeholder?.[idx]
              }
              value={value?.[idx] || otpValue?.[idx]}
              id={`mt-input-otp-${idx}`}
              {...rest}
            />
          ))}
      </div>
    );
  }
);

export default MTInputOTP;
