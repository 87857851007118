import Endpoints from '@/configs/router';
import { AppRouter, TEndpoints } from '@/configs/router/router.types';
import { RouterKey } from '@/domains/common';

const RoutesBase = {
  [Endpoints.BaseHome]: {
    name: 'Home',
    path: Endpoints.BaseHome,
    element: null,
    navigate: Endpoints.ShipmentList,
  },
  [Endpoints.BaseUserManagement]: {
    name: 'User Management',
    path: Endpoints.BaseUserManagement,
    element: null,
    navigate: Endpoints.UserManagementUserList,
    routerKey: RouterKey.USER_USER_VIEW,
    asFirst: true,
  },
  [Endpoints.BaseSettings]: {
    name: 'Settings',
    path: Endpoints.BaseSettings,
    element: null,
    routerKey: RouterKey.SETTING_UPLOAD_COMPANY_LOGO,
  },
} as Record<TEndpoints, AppRouter>;

export default RoutesBase;
