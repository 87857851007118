import { useMemo } from 'react';

import { Navigate, RouteObject, RouterProvider } from 'react-router-dom';

import Endpoints from '@/configs/router';
import { EUserAuthStatus } from '@/domains/user-management/user/user.constants';
import { useRole } from '@/hooks';
import { sentryCreateBrowserRouter } from '@/sentry';
import { useAuthStore } from '@/store';

import RouterList from './routes';

function Router() {
  const { isAuthenticated, userInfo } = useAuthStore();
  const { isUserAllowed } = useRole();

  const accessibleRoutePath = useMemo(() => {
    const accessibleRoute = RouterList.find((route) => {
      return (
        route.mode === 'private' &&
        isUserAllowed({
          routerKey: route.routerKey,
        }) &&
        route.asFirst
      );
    });

    if (userInfo?.auth_status === EUserAuthStatus.ChooseCargoOwner)
      return Endpoints.SelectCOCompany;
    if (accessibleRoute) return accessibleRoute.path;
    return Endpoints.Unauthorized;
  }, [isUserAllowed, userInfo?.auth_status]);

  const modifiedRouter = useMemo(() => {
    const res: RouteObject[] = RouterList.map((data) => {
      let element = data.element;

      switch (data.mode) {
        case 'private':
          if (!isAuthenticated) {
            element = <Navigate to={Endpoints.Login} />;
          } else if (!isUserAllowed({ routerKey: data.routerKey })) {
            element = <Navigate to={accessibleRoutePath} />;
          }
          break;
        case 'public':
          if (isAuthenticated) {
            element = <Navigate to={accessibleRoutePath} />;
          }
          break;
        default:
          if (!data.element) {
            element = (
              <Navigate to={data.navigate || Endpoints.BaseHome} replace />
            );
          }
          break;
      }

      return { path: data.path, element };
    });

    return res;
  }, [accessibleRoutePath, isAuthenticated, isUserAllowed]);

  const router = sentryCreateBrowserRouter(modifiedRouter);

  return <RouterProvider router={router} />;
}

export default Router;
