import EApiAuth from './auth/auth.api';
import EApiDocumentCollection from './document-collection/document-collection.api';
import EApiMonitoring from './monitoring/monitoring.api';
import EApiProfile from './profile/profile.api';
import EApiShipment from './shipment/shipment.api';
import EApiUploader from './uploader/uploader.api';
import EApiRoleGroup from './user-management/role-group.api';
import EApiUser from './user-management/user.api';

const ApiEndpoints = {
  ...EApiAuth,
  ...EApiMonitoring,
  ...EApiProfile,
  ...EApiRoleGroup,
  ...EApiUploader,
  ...EApiShipment,
  ...EApiUser,
  ...EApiDocumentCollection,
};

export default ApiEndpoints;
