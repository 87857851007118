/**
 * @description For checking out of bound of screen position.
 *
 * `positive` represents `top` and `left`, while `negative` represents `bottom` and `right`.
 * @param positiveNumber Top or Left Number.
 * @param negativeNumber Bottom or Right Number.
 * @param screenWidth Width of the Screen.
 * @returns
 */
export const checkPositiveNegative = (
  positiveNumber: number,
  negativeNumber: number,
  screenWidth: number
): 'positive' | 'negative' | undefined => {
  if (positiveNumber > 0 && negativeNumber > screenWidth) return 'positive';
  if (positiveNumber < 0 && negativeNumber < screenWidth) return 'negative';
};
