import { ThemeConfig } from 'antd';

import {
  kColorDanger,
  kColorNeutral,
  kColorPrimary,
  kColorSuccess,
  kColorWarning,
} from '@/constants/theme/colorSystem.constants';

export const antdTheme: ThemeConfig = {
  token: {
    borderRadius: 8,
    colorLink: kColorPrimary.main,
    colorPrimary: kColorPrimary.main,
    colorPrimaryText: kColorPrimary.main,
    colorPrimaryHover: kColorPrimary[3],
    colorPrimaryActive: kColorPrimary[4],
    colorInfo: kColorNeutral.main,
    colorInfoText: kColorNeutral.main,
    colorInfoHover: kColorNeutral[3],
    colorInfoActive: kColorNeutral[4],
    colorText: kColorNeutral.main,
    colorError: kColorDanger.main,
    colorErrorText: kColorDanger.main,
    colorErrorHover: kColorDanger[3],
    colorErrorActive: kColorDanger[4],
    colorSuccess: kColorSuccess.main,
    colorSuccessText: kColorSuccess.main,
    colorSuccessHover: kColorSuccess[3],
    colorSuccessActive: kColorSuccess[4],
    colorWarning: kColorWarning.main,
    colorWarningText: kColorWarning.main,
    colorWarningHover: kColorWarning[3],
    colorWarningActive: kColorWarning[4],
  },
};
