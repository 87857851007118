import ApiEndpoints from '@/domains/api-endpoints';
import { TCommonApiResponse } from '@/domains/common';
import {
  TGetShipmentTrackerListParams,
  TGetShipmentTrackerListResponse,
  TGetVesselTrackerDetailParams,
  TGetVesselTrackerDetailResponse,
  TGetVesselTrackerParams,
  TGetVesselTrackerResponse,
} from '@/domains/monitoring';

import marineApi from '../services.config';

export default class MonitoringApi {
  getShipmentTracker(
    params?: TGetShipmentTrackerListParams
  ): Promise<TCommonApiResponse<TGetShipmentTrackerListResponse>> {
    return marineApi.barging.get(ApiEndpoints.shipmentTracker, { params });
  }

  getVesselTracker(
    params?: TGetVesselTrackerParams
  ): Promise<TCommonApiResponse<TGetVesselTrackerResponse[]>> {
    return marineApi.barging.get(ApiEndpoints.getVesselTracker, { params });
  }

  getVesselTrackerDetail(
    params: TGetVesselTrackerDetailParams
  ): Promise<TCommonApiResponse<TGetVesselTrackerDetailResponse>> {
    return marineApi.barging.get(
      ApiEndpoints.getVesselTrackerDetail.replace(':id', params.id)
    );
  }
}
