import MTAlert from './mt-alert';
import MTAvatar from './mt-avatar';
import MTButton from './mt-button';
import MTCard from './mt-card/basic';
import MTCardExpandable from './mt-card/expandable';
import MTCheckboxMulti from './mt-checkbox/multi';
import MTChip from './mt-chip';
import MTErrorBoundary from './mt-error-boundary';
import type { MTFormProps } from './mt-form';
import { MTHeaderLoader } from './mt-header';
import MTInput from './mt-input/basic';
import MTInputDateRange from './mt-input/date-range';
import MTInputDropdownCheckbox from './mt-input/dropdown-checkbox';
import MTInputOTP from './mt-input/otp';
import MTInputPassword from './mt-input/password';
import MTInputSelect from './mt-input/select';
import MTLazy, { MTLazyComponent } from './mt-lazy';
import MTMapBox from './mt-mapbox';
import { MTModalProps } from './mt-modal/basic';
import { MTModalConfirmationProps } from './mt-modal/confirmation';
import MTPreviewer from './mt-previewer';
import type { MTSeoMetaProps } from './mt-seo-meta';
import MTSeoMeta from './mt-seo-meta';
import MTSider from './mt-sider';
import MTSpinner from './mt-spinner';
import MTTable from './mt-table';
import MTToast from './mt-toast';
import MTTreeSelect from './mt-tree-select';
import { MTUploaderAvatarProps } from './mt-uploader/avatar/MTUploaderAvatar.types';
import MTUploader from './mt-uploader/basic';
import MTViewerImage from './mt-viewer/image';
import MTViewerPDF from './mt-viewer/pdf';

const MTForm = MTLazyComponent<MTFormProps>(() => import('./mt-form'));
const MTHeader = MTLazyComponent(
  () => import('./mt-header'),
  <MTHeaderLoader />
);
const MTModal = MTLazyComponent<MTModalProps>(() => import('./mt-modal/basic'));
const MTModalConfirmation = MTLazyComponent<MTModalConfirmationProps>(
  () => import('./mt-modal/confirmation')
);
const MTUploaderAvatar = MTLazyComponent<MTUploaderAvatarProps>(
  () => import('./mt-uploader/avatar')
);

export {
  MTAlert,
  MTAvatar,
  MTButton,
  MTCard,
  MTCardExpandable,
  MTCheckboxMulti,
  MTChip,
  MTErrorBoundary,
  MTForm,
  MTHeader,
  MTInput,
  MTInputDateRange,
  MTInputDropdownCheckbox,
  MTInputOTP,
  MTInputPassword,
  MTInputSelect,
  MTLazy,
  MTLazyComponent,
  MTMapBox,
  MTModal,
  MTModalConfirmation,
  MTPreviewer,
  MTSeoMeta,
  MTSeoMetaProps,
  MTSider,
  MTSpinner,
  MTTable,
  MTToast,
  MTTreeSelect,
  MTUploader,
  MTUploaderAvatar,
  MTViewerImage,
  MTViewerPDF,
};
