const kStorageKey = {
  SidebarCollapsible: 's_co',
  Cookies: {
    UserInfoToken: 'ma_ui_t',
    AccessToken: 'ma_ac_t',
    IDToken: 'ma_id_t',
    RefreshToken: 'ma_rt_t',
  },
} as const;

export default kStorageKey;
