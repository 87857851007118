import { Skeleton } from 'antd';

import MTTable from '.';

const MTTableLoader = () => {
  return (
    <MTTable
      columns={new Array(5).fill(null).map(() => ({
        title: <Skeleton paragraph={false} />,
        render: () => <Skeleton paragraph={false} />,
      }))}
      dataSource={new Array(5).fill(null).map((_, index) => ({ index }))}
      rowKey="index"
    />
  );
};

export default MTTableLoader;
