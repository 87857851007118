import dayjs, { Dayjs, extend } from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { timezoneAbbr } from '@/constants/common/date.constants';

extend(advanced);
extend(timezone);
extend(utc);

type TTimezoneAbbr = keyof typeof timezoneAbbr;
export type TDate = string | number | dayjs.Dayjs | null | Date;

export const guessTZ: TTimezoneAbbr = dayjs.tz.guess() as TTimezoneAbbr;

/** Utility for checking if value is a valid date
 * @param value value to be checking if it's a valid date
 */
export const dateIsValid = (value: TDate) => dayjs(value).isValid();

/** Utility for formatting date based on your current timezone
 *
 * Example: '01 Jan 1970 00:00 WIB'
 */
export const dateFormat = ({
  date,
  format = 'DD MMM YYYY',
  tz,
  tzAbbr,
}: {
  /** [OPTIONAL]: Date that want to be formatted, or leave it undefined to format current date and time */
  date?: TDate;
  /** [OPTIONAL]: Format for your Date and time,
   *
   * Default: 'DD MMM YYYY' => '01 Jan 1970'
   */
  format?: string;
  /** [OPTIONAL]: Only use it for specific timezone, leave it for guessing timezone.
   *
   * Default: Your current timezone
   */
  tz?: TTimezoneAbbr;
  /** [OPTIONAL]: Set to true if you want to show timezone abbreviation.
   *
   * Example: '01 Jan 1970 00:00 WIB'
   */
  tzAbbr?: boolean;
}): string => {
  // * This if block will run if you have specific timezone, for example, Asia/Jakarta time only
  if (date === null) return '-';
  if (tz)
    return `${dayjs(date)
      .tz(tz as string)
      .format(format)}${tzAbbr ? ` ${timezoneAbbr[tz]}` : ''}`;

  return `${dayjs(date).format(format)}${
    tzAbbr ? ` ${timezoneAbbr[guessTZ]}` : ''
  }`;
};

/** Utility for formatting date based on UTC
 *
 * Example: '01 Jan 1970 00:00 UTC'
 */
export const dateFormatUTC = ({
  /** [OPTIONAL]: Date that want to be formatted, or leave it undefined to format current date and time */
  date,
  /** [OPTIONAL]: Format for your Date and time,
   *
   * Default: 'DD MMM YYYY' => '01 Jan 1970'
   */
  format = 'DD MMMM YYYY, HH:mm',
  /** [OPTIONAL]: Set to true if you want to see 'UTC' as timezone abbrevation
   *
   * Example: '01 Jan 1970 00:00 UTC'
   */
  showUTC,
}: {
  date?: TDate;
  format?: string;
  showUTC?: boolean;
}): string => `${dayjs(date).utc().format(format)}${showUTC ? ' UTC' : ''}`;

/** Utility for formatting date with midnight time minus your offset timezone.
 *
 * Example:
 *    input => Moment object or Dayjs object => 1970-01-01T00:00:00.000+07:00
 *    output => 1969-12-31T17:00:00.000Z
 */
export const dateOnlyUTC = ({
  date,
  timezone,
}: {
  date: dayjs.Dayjs;
  timezone?: TTimezoneAbbr;
}): string => {
  let output: dayjs.Dayjs = date;
  if (timezone) output = output?.tz(timezone);

  output = output
    ?.set('hour', 0)
    ?.set('minute', 0)
    ?.set('second', 0)
    ?.set('millisecond', 0);

  return timezone
    ? output
        ?.utc()
        ?.format('YYYY-MM-DD[T]HH:mm:ss.[000]Z')
        ?.replace(/(\+00:00)/g, 'Z')
    : output?.toISOString();
};

/** Utility for combining date and time input and format it to ISO string.
 *
 * Example:
 *    input => {date: 1970-01-02, time: 09:00, timezone: Asia/Jakarta}
 *    output => 1970-01-02T09:00:00.000+07:00
 */
export const dateTimeLocalISO = ({
  date,
  isZeroSecond = false,
  time,
  timezone,
  toUTC,
}: {
  /** Date that want to be formatted, */
  date: TDate;
  /** To make second always zero */
  isZeroSecond?: boolean;
  /** Time that want to be formatted, */
  time: TDate;
  /** [OPTIONAL]: Only use it for specific timezone, leave it for guessing timezone.
   *
   * Default: Your current timezone
   */
  timezone?: TTimezoneAbbr;
  /** [OPTIONAL]: Additional feature that if you want to reconvert it to UTC.*/
  toUTC?: boolean;
}): string => {
  const d = dayjs(date).format('YYYY-MM-DD');
  const t = dayjs(time).format(`HH:mm:${isZeroSecond ? '[00]' : 'ss'}.[000]`);
  const tz = timezone ? dayjs().tz(timezone).format('Z') : dayjs().format('Z');

  if (toUTC) return dayjs(`${d}T${t}${tz}`).utc().toISOString();

  return `${d}T${t}${tz}`;
};

/** Utility for calculating time difference.
 *
 * Example:
 *    input => {earlierTime: 2023-01-11T00:00:00.000+07:00, laterTime: 2023-01-11T08:21:17.000+07:00, unit: 'd', decimal: true,}
 *    output => 0.3481134259259259
 */
export const calculateTimeDifference = ({
  earlierTime,
  laterTime,
  unit = 's',
  decimal = false,
}: {
  /** Earlier time to be calculated */
  earlierTime: string | Dayjs;
  /** Later time to be deducted */
  laterTime: string | Dayjs;
  /** [OPTIONAL]: unit of measurement (day ('d'), hour ('h'), minute ('m'), or second ('s') ).
   *
   * Default: second
   */
  unit?: 'd' | 'h' | 'm' | 's';
  /** [OPTIONAL]: return calculation as floating point number.
   *
   * Default: false
   */
  decimal?: boolean;
}): number => {
  return dayjs(laterTime).diff(earlierTime, unit, decimal);
};

/**
 * Utility for formatting time format
 *
 * Example:
 *  input => "2023-01-07T20:30:00.000Z
 *  output => 03:30:00
 *
 */

export const timeFormat = ({
  date,
  format = 'kk:mm:ss',
  tz,
  tzAbbr,
}: {
  /** [OPTIONAL]: Date that want to be formatted, or leave it undefined to format current date and time */
  date?: TDate;
  /** [OPTIONAL]: Format for your Date and time,
   *
   * Default: 'DD MMM YYYY' => '01 Jan 1970'
   */
  format?: string;
  /** [OPTIONAL]: Only use it for specific timezone, leave it for guessing timezone.
   *
   * Default: Your current timezone
   */
  tz?: TTimezoneAbbr;
  /** [OPTIONAL]: Set to true if you want to show timezone abbreviation.
   *
   * Example: '01 Jan 1970 00:00 WIB'
   */
  tzAbbr?: boolean;
}): string => {
  // * This if block will run if you have specific timezone, for example, Asia/Jakarta time only
  const [hour, minute, second] = dayjs(date).format(format).split(':');
  const formattedTime =
    hour === '24' ? `00:${minute}:${second}` : `${hour}:${minute}:${second}`;

  if (tz) {
    const [hour, minute, second] = dayjs(date)
      .tz(tz as string)
      .format(format)
      .split(':');
    const withTimezone =
      hour === '24' ? `00:${minute}:${second}` : `${hour}:${minute}:${second}`;
    return `${withTimezone}${tzAbbr ? ` ${timezoneAbbr[tz]}` : ''}`;
  }

  return `${formattedTime}${tzAbbr ? ` ${timezoneAbbr[guessTZ]}` : ''}`;
};

/**
 * Utility to format time used into time-like format
 *
 * Example:
 *  input: {
 *    hours: 3,
 *    minutes: 30,
 *    seconds: 0
 *  }
 *  output: 3:30:00
 */
export const timeUsedFormat = ({
  hours,
  minutes,
  seconds,
}: {
  hours: number;
  minutes: number;
  seconds: number;
}) => {
  return `${hours}:${minutes > 9 ? minutes : `0${minutes}`}:${
    seconds > 9 ? seconds : `0${seconds}`
  }`;
};
