import { MTLazy } from '@/components';
import Endpoints from '@/configs/router';
import { AppRouter, TEndpoints } from '@/configs/router/router.types';

const RoutesExample = {
  [Endpoints.Example]: {
    name: 'Example',
    path: Endpoints.Example,
    element: MTLazy(() => import('@/pages/example')),
    navigate: Endpoints.Login,
  },
} as Record<TEndpoints, AppRouter>;

export default RoutesExample;
