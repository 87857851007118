export enum EUserAuthType {
  BasicUserLogin = 'BASIC_USER_LOGIN',
  CargoOwnerSelection = 'CARGO_OWNER_SELECTION',
  ForceChangePassword = 'FORCE_CHANGE_PASSWORD',
  LoginWithCode = 'LOGIN_WITH_CODE',
  RefreshToken = 'REFRESH_TOKEN',
}

export enum EUserAuthStatus {
  Successful = 'SUCCESSFUL',
  ChooseCargoOwner = 'CHOOSE_CARGO_OWNER',
  ForceChangePassword = 'FORCE_CHANGE_PASSWORD',
  UserUnlocked = 'USER_UNLOCKED',
}

export enum EUserCheckpointType {
  UserActivation = 'USER_ACTIVATION',
  UserReactivation = 'USER_REACTIVATION',
  ResetPassword = 'RESET_PASSWORD',
}

export enum EUserPlatformType {
  CargoOwnerDashboard = 'CARGO_OWNER_DASHBOARD',
}

export enum EUserErrorType {
  CredentialRefreshed = 'CREDENTIAL_REFRESHED',
  EmailIsNotRegistered = 'EMAIL_IS_NOT_REGISTERED',
  ForbiddenAccess = 'FORBIDDEN_ACCESS',
  ForgotPasswordUnavailable = 'FORGOT_PASSWORD_UNAVAILABLE',
  InvalidCredential = 'INVALID_CREDENTIAL',
  InvalidOTPCode = 'INVALID_OTP_CODE',
  TokenInvalid = 'TOKEN_INVALID',
  TooManyRequest = 'TOO_MANY_REQUEST',
  Unknown = 'UNKNOWN',
  UserHasNoRole = 'USER_HAS_NO_ROLE',
  UserLocked = 'USER_LOCKED',
  UserNotFound = 'USER_NOT_FOUND',
  UserNotActivated = 'USER_NOT_ACTIVATED',
  UserSuspended = 'USER_SUSPENDED',

  // #region EXPIRED
  InvitationURLExpired = 'INVITATION_URL_EXPIRED',
  OTPExpired = 'OTP_EXPIRED',
  SessionExpired = 'SESSION_EXPIRED',
  TokenExpired = 'TOKEN_EXPIRED',
  // #endregion
}

export enum EUserForceChangePasswordType {
  NewInvitation = 'NEW_INVITATION',
  UserLocked = 'USER_LOCKED',
}
