/* eslint-disable @typescript-eslint/no-explicit-any */

import { Table, TablePaginationConfig } from 'antd';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/es/table/interface';
import cx from 'classnames';

import './style.scss';

import { Action, Filter, Sorter, Tag } from './subcomponents';

import { MTTableProps } from '.';

const MTTable = <Data extends object>({
  className,
  isHideSorter = true,
  hideOnSinglePage = false,
  scrollTop = true,
  paginationConfig,
  onChange,
  ...props
}: React.PropsWithChildren<MTTableProps<Data>>) => {
  const handleChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>
  ) => {
    scrollTop && window.scrollTo(0, 0);
    onChange?.(pagination, filters, sorter, extra);
  };

  return (
    <Table
      onChange={handleChange}
      className={cx(
        'mt-table',
        className,
        isHideSorter && 'mt-table--hide_sorter'
      )}
      pagination={{
        hideOnSinglePage,
        showSizeChanger: true,
        showTotal: (total, [first, last]) =>
          `${first}-${last} of ${total} items`,
        ...paginationConfig,
      }}
      {...props}
    />
  );
};

MTTable.Action = Action;
MTTable.Filter = Filter;
MTTable.Sorter = Sorter;
MTTable.Tag = Tag;

export default MTTable;
