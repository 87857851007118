import { forwardRef } from 'react';

import { Button } from 'antd';
import cx from 'classnames';

import type { MTButtonProps } from './MTButton.types';

import './style.scss';

const MTButton = forwardRef<HTMLElement, MTButtonProps>(
  (
    {
      block = false,
      children,
      className,
      color = 'default',
      fontWeight = 'regular',
      size = 'sm',
      type = 'primary',
      ...rest
    },
    ref
  ) => {
    return (
      <Button
        className={cx(
          'mt-button',
          !block && 'mt-button--non-block',
          color && color !== 'danger' && `mt-button--color-${color}`,
          `mt-button--size-${size}`,
          className
        )}
        ref={ref}
        ghost={type === 'ghost'}
        type={type === 'ghost' ? 'primary' : type}
        danger={color === 'danger'}
        {...rest}>
        {children}
      </Button>
    );
  }
);

MTButton.displayName = 'MTButton';

export default MTButton;
