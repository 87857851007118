import { forwardRef } from 'react';

import cx from 'classnames';

import type { ViewerMaskProps } from './ViewerMask.types';

import './style.scss';

const ViewerMask = forwardRef<HTMLDivElement, ViewerMaskProps>(
  ({ children, isShow, onClick }, ref) => {
    return (
      <div>
        <div className="mt-viewer">
          {isShow && (
            <div
              ref={ref}
              className={cx('mt-viewer__mask', 'mt-fade', 'mt-fade-in')}
              onClick={onClick}
            />
          )}

          {children}
        </div>
      </div>
    );
  }
);

export default ViewerMask;
